import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropDownList, type DropDownListFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { type FilterDescriptor, type CompositeFilterDescriptor, filterBy } from '@progress/kendo-data-query';

import type { RootState } from '../../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../../../shared/FullHeightSpinner/FullHeightSpinner';
import type { CustomDataOpsData } from '../../../../interfaces/customDataOpsInterface/customDataOpsInterface';
import { CustomToolTabContentIframe } from '../../../Workflow/WorkflowTabContent/WorkflowDesignTabContent/WorkflowDesignTabs/LogicTabContent/TabContent/CustomToolTabContent/CustomToolTabContentIframe/CustomToolTabContentIframe';
import { fetchGetJson } from '../../../../services/services';

type Props = {
  selectedItem: CustomDataOpsData;
}

type Project = {
  id: string;
  name: string;
}

type Document = {
  id: string;
  name: string;
  type: string;
  projectId: string;
  surveyStatus?: number;
}

const noCache = new Date().getTime();
let allProjects: Project[] = [];
let allDocuments: Document[] = [];
const defaultDocument = { name: "Select a document...", id: "1", projectId: "1", type: "dataset" };

export const CustomDataOpsPreviewTabContent = ({ selectedItem }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [projects, setProjects] = useState<Project[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | undefined>(undefined);
  const [selectedDocument, setSelectedDocument] = useState<Document>(defaultDocument);
  const [iFrameUrl, setIframeUrl] = useState(selectedItem.htmlUrl ? `${selectedItem.htmlUrl}?nocache=${noCache}` : "");
  const [dataForIframe, setDataForIframe] = useState({ inputStructure: {}, filter: "", props: {}, state: {} });

  useEffect(() => {
    fetchGetJson("projects", token)
      .then((data: TODO) => {
        if (data.error || data.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error || data.message } });
        } else {
          setIsLoadingProjects(false);
          setProjects(data);
          allProjects = data;
        }
      })
  }, [token, dispatch]);

  const selectProjectHandler = (project: TODO) => {
    setSelectedProject(project);
    setDocuments([]);
    setSelectedDocument(defaultDocument);
    setIsLoadingDocuments(true);
    fetchGetJson(`projects/${project.id}/documents`, token)
      .then((data: TODO) => {
        if (data.error || data.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error || data.message } });
        } else {
          setIsLoadingDocuments(false);
          setDocuments([...data.datasets].concat(data.surveys.filter((dataset: TODO) => dataset.type === "survey" && dataset.surveyStatus === 1)));
          allDocuments = [...data.datasets].concat(data.surveys.filter((dataset: TODO) => dataset.type === "survey" && dataset.surveyStatus === 1));
        }
      })
  }

  const selectDocumentHandler = (document: TODO) => {
    setSelectedDocument(document);
    if (selectedProject && document) {
      setIframeUrl("");
      const { projectId, id: documentId } = document;
      const structureUrl = document.type === 'survey' ? `an/projects/${projectId}/analysis/surveys/${documentId}/structure` : `an/projects/${projectId}/analysis/${documentId}/structure`;
      fetchGetJson(structureUrl, token)
        .then((res: TODO) => {
          if (!res.error && !res.messsage) {
            setIframeUrl(selectedItem.htmlUrl ? `${selectedItem.htmlUrl}?nocache=${noCache}` : "");
            setDataForIframe({ inputStructure: res, filter: "", props: [], state: {} });
          } else {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.message || res.error } });
          }
        })
    } else {
      alert('Please select a project and a document');
    }
  }

  const filterProjects = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    const data = allProjects.slice();
    return filterBy(data, filter);
  };

  const filterProjectsChange = (event: DropDownListFilterChangeEvent) => {
    setProjects(filterProjects(event.filter));
  };

  const filterDocuments = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    const data = allDocuments.slice();
    return filterBy(data, filter);
  };

  const filterDocumentsChange = (event: DropDownListFilterChangeEvent) => {
    setDocuments(filterDocuments(event.filter));
  };

  return (
    <div className="d-flex flex-column gap-lg w-100">
      <div className="d-flex gap-lg w-50">
        <DropDownList
          filterable
          data={projects}
          textField='name'
          className='bg-white'
          value={selectedProject}
          loading={isLoadingProjects}
          defaultValue={{ name: "Select a project..." }}
          onFilterChange={filterProjectsChange}
          onChange={(e) => selectProjectHandler(e.value)}
        />
        <DropDownList
          filterable
          data={documents}
          textField='name'
          className='bg-white'
          value={selectedDocument}
          disabled={!selectedProject}
          loading={isLoadingDocuments}
          onFilterChange={filterDocumentsChange}
          onChange={(e) => selectDocumentHandler(e.value)}
        />
      </div>
      {
        iFrameUrl && iFrameUrl !== "" ?
          <CustomToolTabContentIframe
            iframeUrl={iFrameUrl}
            dataForIframe={dataForIframe}
          />
          :
          <FullHeightSpinner />
      }
    </div>
  )
}