import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../store/reducers/rootReducer";
import { CustomDataOpsHeader } from "./CustomDataOpsHeader/CustomDataOpsHeader";
import { FullHeightSpinner } from "../../shared/FullHeightSpinner/FullHeightSpinner";
import { CustomDataOpsEditTabContent } from "./CustomDataOpsEditTabContent/CustomDataOpsEditTabContent";
import { CustomDataOpsPreviewTabContent } from "./CustomDataOpsPreviewTabContent/CustomDataOpsPreviewTabContent";
import { updateCustomDataOpsData } from "../../../store/reducers/customDataOpsStateReducer/customDataOpsStateReducer";
import { fetchGetJson } from "../../../services/services";

const viewTabs = ["Edit", "Preview"] as const;

export const CustomDataOpsEditAndPreviewSection = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { selectedItemId, customDataOpsData } = useSelector((state: RootState) => state.customDataOpsStateReducer);
  const [didMount, setDidMount] = useState(false);
  const [editorValues, setEditorValues] = useState({ html: "", properties: "[]" });
  const [activeViewTab, setActiveViewTab] = useState<(typeof viewTabs[number])>('Edit');
  const [isLoading, setIsLoading] = useState(true);

  const selectedItem = customDataOpsData?.find(item => item.id === selectedItemId);

  useEffect(() => {
    if (selectedItemId && !didMount) {
      setDidMount(true);
      fetchGetJson(`su/customtools/${selectedItemId}`, token)
        .then((data: TODO) => {
          setIsLoading(false);
          if (data.error || data.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error || data.message } });
          } else {
            setEditorValues({
              html: data.html,
              properties: data.props ? JSON.stringify(data.props, null, 2) : "[]"
            })
            if (customDataOpsData) {
              dispatch(updateCustomDataOpsData(customDataOpsData.map(item => item.id === selectedItemId ? { ...item, ...data } : item)));
            }
          }
        })
    }
  }, [token, dispatch, didMount, customDataOpsData, selectedItemId])

  return (
    <Fragment>
      {
        isLoading ?
          <FullHeightSpinner />
          :
          selectedItem &&
          <div className="d-flex flex-column w-100 h-100 overflow-hidden bg-white answer-layout">
            <CustomDataOpsHeader
              viewTabs={viewTabs}
              selectedItem={selectedItem}
              activeViewTab={activeViewTab}
              setActiveViewTab={setActiveViewTab}
            />
            <div style={{ flex: '1 1 auto' }} className="d-flex p-4">
              {
                activeViewTab === 'Edit' ?
                  <CustomDataOpsEditTabContent
                    editorValues={editorValues}
                    selectedItem={selectedItem}
                    setEditorValues={setEditorValues}
                  />
                  :
                  <CustomDataOpsPreviewTabContent
                    selectedItem={selectedItem}
                  />
              }
            </div>
          </div>
      }
    </Fragment>
  )
}