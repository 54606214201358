import { useState } from "react";
import { Switch } from "@progress/kendo-react-inputs";
import { useDispatch, useSelector } from "react-redux";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import type { RootState } from "../../../store/reducers/rootReducer";
import type { Tag } from "../../Admin/AdminTabContent/TagsTabContent/TagsTabContent";
import type { CustomDataOpsData } from "../../../interfaces/customDataOpsInterface/customDataOpsInterface";
import { updateCustomDataOpsShowCreateEditModal } from "../../../store/reducers/customDataOpsStateReducer/customDataOpsStateReducer";
import { fetchPatchJson, fetchPostResOrJson } from "../../../services/services";

type Props = {
  tags: Tag[];
  customers: Tag[];
  item?: CustomDataOpsData | null;
  updateDataOpsItems: () => void;
}

type FormData = {
  name: string;
  description: string;
  isInternal: boolean;
  tags: string[];
  customers: { name: string }[];
}

export const CustomDataOpsCreateAndEditModal = ({ tags, customers, item, updateDataOpsItems }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [formData, setFormData] = useState<FormData>({ name: item?.name || '', description: item?.description || '', tags: item?.tags || [], customers: item?.customerAccess || [], isInternal: item?.isInternal || false });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClose = () => dispatch(updateCustomDataOpsShowCreateEditModal({ show: false, item: null }));

  const onChangeHandler = (value: TODO, name: string) => {
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const onCreateOrEdit = () => {
    setIsLoading(true);
    const body = { name: formData.name, description: formData.description, customerAccess: formData.customers, tags: formData.tags, isInternal: formData.isInternal };
    if (item) { // Update item
      fetchPatchJson(`su/customtools/${item.id}`, token, body)
        .then((res: TODO) => res.json())
        .then((data: TODO) => {
          setIsLoading(false);
          if (data.error || data.message) {
            setErrorMessage(data.error || data.message);
          } else {
            handleClose();
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Item updated successfully' } });
            updateDataOpsItems();
          }
        })
    } else { // Create item
      fetchPostResOrJson('su/customtools', token, body)
        .then((data: TODO) => {
          setIsLoading(false);
          if (data.error || data.message) {
            setErrorMessage(data.error || data.message);
          } else {
            handleClose();
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Item created successfully' } });
            updateDataOpsItems();
          }
        })
    }
  }

  return (
    <Dialog title={item ? "Edit item" : "Create new item"} onClose={handleClose} width={500}>
      <div className="p-3">
        <label htmlFor="">Name:</label>
        <input value={formData.name} onChange={(e) => onChangeHandler(e.target.value, 'name')} type="text" className="form-control form-control-sm mb-2" />
        <label htmlFor="">Description:</label>
        <textarea rows={3} value={formData.description} onChange={(e) => onChangeHandler(e.target.value, 'description')} className="form-control mb-2" />
        <p className='mb-1'>Customer access:</p>
        <MultiSelect
          name="tags"
          filterable={true}
          onChange={(e) => onChangeHandler(e.target.value, 'customers')}
          className="w-100 mb-2"
          allowCustom={true}
          textField="name"
          value={formData.customers}
          data={customers ? customers : []}
        />
        <p className='mb-1'>Tags:</p>
        <MultiSelect
          name="tags"
          filterable={true}
          onChange={(e) => onChangeHandler(e.target.value, 'tags')}
          className="w-100 mb-3"
          allowCustom={true}
          value={formData.tags}
          data={tags.map(tag => tag.name)}
        />
        <div className="d-flex align-items-center justify-content-between">
          <p className="mr-3 mb-0">Internal access: </p>
          <Switch onChange={(e) => onChangeHandler(e.value, 'isInternal')} checked={formData.isInternal} size="small" />
        </div>
        {
          errorMessage &&
          <div className="alert alert-danger mt-3 mb-0">
            {errorMessage}
          </div>
        }
      </div>
      <DialogActionsBar>
        <button type="button" className="k-button btn-secondary" onClick={handleClose}>Cancel</button>
        <button disabled={isLoading} type="button" className="k-button btn-primary" onClick={onCreateOrEdit}>
          {item ? "Update" : "Create"}
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}