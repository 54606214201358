import { Fragment } from 'react';
import { Switch } from '@progress/kendo-react-inputs';

import type { ToolInfo } from '../../../../interfaces/workflowInterfaces/toolInterfaces';

interface Props {
  editMode: boolean
  workflowData: TODO
  toolData: ToolInfo
  outputPath: string
  handleUpdateToolData: (type: string, id: TODO) => void
  setShowInputModal: (val: { show: boolean, type: 'input' | 'output' | null }) => void
}

export const WorkflowToolOutputTabContent = ({ editMode, workflowData, toolData, outputPath, handleUpdateToolData, setShowInputModal }: Props) => {
  return (
    <Fragment>
      <div className="d-flex align-items-center mb-3">
        <Switch
          className="mr-4"
          checked={editMode && workflowData.selectedTool ? workflowData.selectedTool.output.save : toolData.output.save}
          onChange={(e: TODO) => handleUpdateToolData('output', { ...(editMode ? workflowData.selectedTool.output : toolData.output), save: e.value })}
          size="small"
        />
        <span>Save results to a project</span>
      </div>
      <span className="mb-2">Save to project</span>
      <div className="d-flex w-100 mb-3">
        <input placeholder="Select project" className="form-control" disabled={true} value={editMode && workflowData.selectedTool ? workflowData.selectedTool.output.projectName : outputPath} />
        <button type='button' className="btn btn-shadow ml-2 flex-shrink-0" onClick={() => setShowInputModal({ show: true, type: 'output' })}>Select</button>
      </div>
      <span className="mb-2">Name</span>
      <input placeholder="Enter dataset name" className="form-control mb-3" value={editMode && workflowData.selectedTool ? workflowData.selectedTool.output.name : toolData.output.name} onChange={(e: TODO) => handleUpdateToolData('output', { ...(editMode ? workflowData.selectedTool.output : toolData.output), name: e.target.value })} />
      <span className="mb-2">Description</span>
      <textarea
        rows={3}
        name="description"
        className="form-control"
        placeholder="Enter dataset description"
        value={editMode && workflowData.selectedTool ? workflowData.selectedTool.output.description : toolData.output.description}
        onChange={(e: TODO) => handleUpdateToolData('output', { ...(editMode ? workflowData.selectedTool.output : toolData.output), description: e.target.value })}
      />
      <div className="d-flex align-items-center mt-4">
        <Switch
          className="mr-4"
          checked={editMode && workflowData.selectedTool ? workflowData.selectedTool.output.overwrite : toolData.output.overwrite}
          onChange={(e: TODO) => handleUpdateToolData('output', { ...(editMode ? workflowData.selectedTool.output : toolData.output), overwrite: e.value })}
          size="small"
        />
        <span>Overwrite output dataset</span>
      </div>
    </Fragment>
  )
}