import { useDispatch, useSelector } from "react-redux";

import { Icon } from "../../shared/Icon/Icon";
import type { RootState } from "../../../store/reducers/rootReducer";
import { DropdownButton } from "../../shared/DropdownButton/DropdownButton";
import type { Tag } from "../../Admin/AdminTabContent/TagsTabContent/TagsTabContent";
import { CustomDataOpsDeleteModal } from "../CustomDataOpsDeleteModal/CustomDataOpsDeleteModal";
import { CustomDataOpsListItemsTable } from "./CustomDataOpsListItemsTable/CustomDataOpsListItemsTable";
import {
  updateCustomDataOpsCustomersFilter,
  updateCustomDataOpsSearchValue,
  updateCustomDataOpsShowCreateEditModal,
  updateCustomDataOpsTagsFilter
} from "../../../store/reducers/customDataOpsStateReducer/customDataOpsStateReducer";

type Props = {
  tags: Tag[];
};

export const CustomDataOpsListItemsSection = ({ tags }: Props) => {
  const dispatch = useDispatch();
  const { customDataOpsData, showDeleteModal, searchValue, tagsFilter, customersFilter } = useSelector((state: RootState) => state.customDataOpsStateReducer);

  const onUpdateFilterTags = (tag: Tag) => {
    if (tagsFilter.includes(tag.name)) {
      dispatch(updateCustomDataOpsTagsFilter(tagsFilter.filter((el: string) => el !== tag.name)));
    } else {
      dispatch(updateCustomDataOpsTagsFilter([...tagsFilter, tag.name]));
    }
  }

  const onUpdateFilterCustomers = (customer: { name: string, id: string }) => {
    if (customersFilter.includes(customer)) {
      dispatch(updateCustomDataOpsCustomersFilter(customersFilter.filter((el) => el !== customer)));
    } else {
      dispatch(updateCustomDataOpsCustomersFilter([...customersFilter, customer]));
    }
  }

  const tagsDropdownItemRender = (item: { text: string }) => {
    if (item.text === 'Header') {
      return (
        <div id='header-item' className='d-flex flex-column'>
          <span className='px-4 pb-2 pt-1 medium strong'>Tags</span>
          <div
            style={{ height: '1px' }}
            className="w-100 my-1 bg-light"
          />
        </div>
      )
    }
    const allTags = customDataOpsData?.flatMap(obj => obj.tags);
    return (
      <div className="d-flex flex-wrap px-3">
        {
          tags?.filter(tag => allTags?.includes(tag.name)).map((tag) => (
            // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
            <div key={tag.id} onClick={() => onUpdateFilterTags(tag)} className={`d-flex align-items-center rounded cursor-pointer mx-1 my-1 ${tagsFilter.includes(tag.name) ? 'filter-selected' : 'filter-default'}`}>
              {tagsFilter.includes(tag.name) ? <Icon type='checkmark' className='mr-1' /> : null}
              <span className={`medium ${tagsFilter.includes(tag.name) ? 'text-analyze' : 'text-primary'}`}>{tag.name}</span>
            </div>
          ))
        }
      </div>
    )
  }

  const customerAccessDropdownItemRender = (item: { text: string }) => {
    if (item.text === 'Header') {
      return (
        <div id='header-item' className='d-flex flex-column'>
          <span className='px-4 pb-2 pt-1 medium strong'>Customer access</span>
          <div
            style={{ height: '1px' }}
            className="w-100 my-1 bg-light"
          />
        </div>
      )
    }
    const allCustomers = customDataOpsData?.flatMap(obj => obj.customerAccess);
    return (
      <div className="d-flex flex-wrap px-3">
        {
          allCustomers?.map((customer) => (
            // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
            <div key={customer.id} onClick={() => onUpdateFilterCustomers(customer)} className={`d-flex align-items-center rounded cursor-pointer mx-1 my-1 ${customersFilter.includes(customer) ? 'filter-selected' : 'filter-default'}`}>
              {customersFilter.includes(customer) ? <Icon type='checkmark' className='mr-1' /> : null}
              <span className={`medium ${customersFilter.includes(customer) ? 'text-analyze' : 'text-primary'}`}>{customer.name}</span>
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <div className="d-flex flex-column w-100 h-100">
      {
        showDeleteModal.show &&
        <CustomDataOpsDeleteModal
          item={showDeleteModal.item}
        />
      }
      <div className="d-flex flex-column justify-content-between p-4 border-bottom gap-sm">
        <div className='d-flex h-32 align-items-center'>
          <h5 className="stronger">Custom data ops</h5>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button type='button' className="btn btn-primary btn-icon icon-l mr-2 pl-2" onClick={() => dispatch(updateCustomDataOpsShowCreateEditModal({ show: true, item: null }))}>
              <Icon type='add' className='mr-1 fill-white' />
              <span>Create new</span>
            </button>
            <div className='h-100 mx-1 bg-light' style={{ width: '1px' }} />
            <DropdownButton
              hideChevron={true}
              text="Tags"
              icon='ic-tags'
              direction='left'
              className='btn-shadow mx-2'
              items={[{ text: 'Header' }, { text: 'Tags' }]}
              renderItem={tagsDropdownItemRender}
            />
            {
              tagsFilter.length > 0 && tagsFilter.map((tag) => (
                <button type='button' key={tag} onClick={() => dispatch(updateCustomDataOpsTagsFilter(tagsFilter.filter((el) => el !== tag)))} className="btn btn-primary py-1 px-2 mr-2">
                  <Icon type='ic-tags' className='mr-1' />
                  <span>{tag}</span>
                  <Icon type='close' className='ml-1' />
                </button>
              ))
            }
            <DropdownButton
              hideChevron={true}
              text='Customers'
              icon='ic-tags'
              direction='left'
              className='btn-shadow mr-2'
              items={[{ text: 'Header' }, { text: 'Customer access' }]}
              renderItem={customerAccessDropdownItemRender}
            />
          </div>
          <div className="d-flex">
            <input
              type="text"
              placeholder="Search"
              className="form-control search-input"
              style={{ width: '16rem' }}
              value={searchValue}
              onChange={(e) => dispatch(updateCustomDataOpsSearchValue(e.target.value))}
            />
          </div>
        </div>
      </div>
      <CustomDataOpsListItemsTable />
    </div>
  )
}