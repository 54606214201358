import { useState } from 'react';
import { useSelector } from 'react-redux';

import { SideBar } from '../../shared/SideBar/SideBar';
import { InboxDrawer } from '../../shared/InboxDrawer/InboxDrawer';
import { RootState } from '../../../store/reducers/rootReducer';
import { Icon } from '../../shared/Icon/Icon';
import { Tooltip } from '@progress/kendo-react-tooltip';

interface Props {
  selectedStatus: string,
  setSelectedStatus: Function,
  setShowUpdateStatusModal: Function
}

export const Header = ({ setSelectedStatus, setShowUpdateStatusModal }: Props) => {
  const { country, audienceStatus, projectName, hasBeenLive,
  } = useSelector((state: RootState) => state.audienceDataReducer);
  const status = hasBeenLive ? ["Live", "Closed", "Pending", "Reconciled"] : ["Draft", "Live", "Closed", "Pending", "Reconciled"]

  const [showActivitySidebar, setShowActivitySidebar] = useState(false);
  const data = useSelector((state: RootState) => state.audienceDataReducer);
  const handleChangeStatus = (item: string) => {
    setSelectedStatus(item)
    setShowUpdateStatusModal(true)
  }

  return (
    <div className='d-flex w-100' >
      {showActivitySidebar &&
        <div className='sidebar-activity'>
          <SideBar width="400px" onHide={() => setShowActivitySidebar(false)}>
            <InboxDrawer />
          </SideBar>
        </div>
      }
      <div className='d-flex justify-content-between align-items-center w-100 flex-row' >
        <div className='pl-4 d-flex align-items-center'>
          <h6 className='stronger m-0 text-truncate cursor-default' title={data.name}>{data.name}</h6>
          <span className={`status-badge badge-${audienceStatus?.toLowerCase()} ml-3 mr-2 mb-0`}><span className='bullet mr-1'></span>{audienceStatus}</span>
          <span className='d-none'>{projectName}</span>
          <span className='d-none'>{country?.name}</span>
        </div>
        <div className='p-0 d-flex flex-row audience-actions'>
          <Tooltip anchorElement="target" position="bottom">
            <button className='btn btn-shadow btn-icon icon-b mr-2' title="Show activity" onClick={() => setShowActivitySidebar(prevState => !prevState)}>
              <Icon type='nav-activity-mini' className='pe-none' />
            </button>
          </Tooltip>
          <div className="dropdown mr-3">
            <button className={`btn btn-primary ${data.audienceStatus === "New" && "disabled"}`} type="button" id="dropdownMenuStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Update status
              {/* <Icon type="chevron-mini-down" /> */}
            </button>
            <div className="dropdown-menu dropdown-menu-right border-0" aria-labelledby="dropdownMenuStatus">
              {status?.filter(item => item !== audienceStatus).map((item, index) => (
                <button key={index} className="dropdown-item" type="button" onClick={() => handleChangeStatus(item)
                }>{item}</button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}