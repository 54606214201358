import { useDispatch, useSelector } from 'react-redux'

import { Icon } from "../../../../shared/Icon/Icon";
import type { RootState } from "../../../../../store/reducers/rootReducer";
import { DropdownButton } from "../../../../shared/DropdownButton/DropdownButton";
import type { Type } from "../../../../../interfaces/iconInterfaces/iconInterfaces";
import type { ToolReference } from "../../../../../interfaces/workflowInterfaces/toolInterfaces";
import itemActions from '../../../../Project/ProjectDetails/ProjectDocumentsTable/menuItemActions.json';
import { fetchPutJson as updateTool, fetchDelete as deleteTool, fetchPostJson as postSelectedTool } from '../../../../../services/services'

interface Props {
  onSelectTool: (e: string) => void
  item: ToolReference
  toolSelected: string | null
  setToolSelected: (e: string | null) => void
  updateData: () => void
  token: string
}

export const ToolCard = ({ onSelectTool, item, toolSelected, setToolSelected, updateData, token }: Props) => {
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);
  const toolIcon: { [k: string]: Type } = {
    Append: "append",
    CleanAndDefine: "advanced",
    Match: "match",
    Coding: "coding"
  }
  const isToolDisabled = workflowData?.selectedTool?.isDisabled
  const dispatch = useDispatch()

  const toolDropDownOptions = (isDisabled: boolean) => {
    if (isDisabled) {
      return itemActions.dataOperations.filter(option => option.text !== 'Disable')
    }
    return itemActions.dataOperations.filter(option => option.text !== 'Enable')
  }

  const updateSelectedTool = (field: string, value: TODO) => {
    const body = { ...workflowData.selectedTool, [field]: value }
    updateTool(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}/tools/${item.referenceId}`, token, body)
      .then((res: TODO) => {
        if (res && !res.message && !res.error) {
          dispatch({ type: 'SET_TOOL_SELECTED', payload: res })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
        }
      })
  }

  const duplicateSelectedTool = () => {
    postSelectedTool(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}/tools/${item.referenceId}`, token)
      .then((res: TODO) => {
        if (res && !res.message && !res.error) {
          dispatch({ type: 'UPDATE_WORKFLOW_DATA', payload: res.workflow })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
        }
      })
  }

  const onDeleteTool = () => {
    if (workflowData.selectedTool && toolSelected === workflowData.selectedTool.id) {
      dispatch({ type: 'SET_TOOL_SELECTED', payload: null })
    }
    dispatch({ type: 'LOADING_WORKFLOW_DATA' });
    deleteTool(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}/tools/${item.referenceId}`, token)
      .then((res: TODO) => {
        if (res && res.status === 200) {
          updateData()
          setToolSelected(null)
        } else return res.json()
      })
      .then((error: TODO) => {
        if (error && (error.message || error.error)) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${error.error ? error.error : error.message}` } })
        }
      })
  }

  const dropDownButtonHandler = (action: string) => {
    switch (action) {
      case "Duplicate":
        duplicateSelectedTool()
        break
      case "Enable":
        updateSelectedTool('isDisabled', false)
        break
      case "Disable":
        updateSelectedTool('isDisabled', true)
        break
      case "Delete":
        onDeleteTool();
        break
      default:
        break
    }
  };

  const returnToolType = (tool: ToolReference) => {
    if (tool.isCustom) return 'Custom';
    return item.toolType === 'CleanAndDefine' ? 'Advanced' : item.toolType;
  }

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div className="tool-card flex-grow-1 d-flex align-items-center cursor-pointer" onClick={() => onSelectTool(item.referenceId)}>
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex justify-content-between header">
          <div className="d-flex align-items-center">
            <Icon type={item.isCustom ? 'ic-carousel' : toolIcon[item.toolType]} />
            <span className="ml-1 strong">
              {returnToolType(item)}
            </span>
          </div>
          <DropdownButton
            hideChevron={true}
            icon="more-horizontal"
            className="btn-transparent p-0"
            items={toolDropDownOptions(isToolDisabled)}
            onItemClick={(e) => dropDownButtonHandler(e.item.text)}
          />
        </div>
        <div className="d-flex align-items-center name w-100">
          <span className="w-100">
            {item.referenceDisplayName}
          </span>
        </div>
      </div>
    </div>
  )
}