import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useFetchTags } from "../shared/customHooks/useFetchTags";
import type { RootState } from "../../store/reducers/rootReducer";
import { CustomDataOpsListItemsSection } from "./CustomDataOpsListItemsSection/CustomDataOpsListItemsSection";
import { updateCustomDataOpsData } from "../../store/reducers/customDataOpsStateReducer/customDataOpsStateReducer";
import { CustomDataOpsCreateAndEditModal } from "./CustomDataOpsCreateAndEditModal/CustomDataOpsCreateAndEditModal";
import { CustomDataOpsEditAndPreviewSection } from "./CustomDataOpsEditAndPreviewSection/CustomDataOpsEditAndPreviewSection";
import { fetchGetJson } from "../../services/services";

export const CustomDataOps = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { showCreateEditModal, selectedItemId } = useSelector((state: RootState) => state.customDataOpsStateReducer);
  const [shouldUpdateItems, setShouldUpdateItems] = useState(false);

  const tags = useFetchTags(token);
  const customers = useFetchTags(token, 'su/customtoolcustomers');

  useEffect(() => {
    if (!shouldUpdateItems) {
      setShouldUpdateItems(true);
      fetchGetJson('su/customtools', token)
        .then((data: TODO) => {
          if (data.error || data.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error || data.message } });
          } else {
            dispatch(updateCustomDataOpsData(data));
          }
        })
    }
  }, [token, shouldUpdateItems, dispatch])

  return (
    <section className='main files-container vh-100 overflow-hidden'>
      {
        showCreateEditModal.show &&
        <CustomDataOpsCreateAndEditModal
          tags={tags}
          customers={customers}
          item={showCreateEditModal.item}
          updateDataOpsItems={() => setShouldUpdateItems(false)}
        />
      }
      {
        selectedItemId ?
          <CustomDataOpsEditAndPreviewSection />
          :
          <CustomDataOpsListItemsSection tags={tags} />
      }
    </section>
  )
}