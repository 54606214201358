import React, { useEffect, useState } from 'react';
import JsxParser from 'react-jsx-parser';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Button, DropDownButton } from '@progress/kendo-react-buttons';

import items from '../exportItems.json';
import defaultOptions from '../../analysis/defaultOptionValues.json'
import dataClearItems from './dataClearItems.json'
import { ExportModal } from '../../ExportModal/ExportModal';
import { OptionsDialog } from '../../OptionsDialog/OptionsDialog';
import DroppableColumn from '../../../DroppableColumn/DroppableColumn';
import { ZoomButtons } from '../../../../shared/ZoomButtons/ZoomButtons';
import { ErrorMessage } from '../../../../../../../shared/ErrorMessage/ErrorMessage';
import { ExportActionItem } from '../../../../shared/ExportActionItem/ExportActionItem';
import { InProgressOverlay } from '../../../../../../../shared/InProgressOverlay/InProgressOverlay';
import UpgradePlanMessage from '../../../../../../../shared/UpgradePlanMessage/UpgradePlanMessage';
import returnActionsData from '../../../../shared/helpers/returnActionsData/returnActionsData';
import returnInitialOptions from '../../../../shared/helpers/returnInitialOptions/returnInitialOptions';
import { returnUpdatedAnalysisBody } from '../../../../shared/helpers/returnUpdatedAnalysisBody/returnUpdatedAnalysisBody';
import returnUpdatedAnalysisActionItems from '../../../../shared/helpers/returnUpdatedAnalysisActionItems/returnUpdatedAnalysisActionItems';
import returnRawDataOptionsInitialValues from '../../../../shared/helpers/returnRawDataOptionsInitialValues/returnRawDataOptionsInitialValues'
import WeightWizard from '../../WeightWizard/WeightWizard';
import usePrevious from '../../../../../../../shared/customHooks/usePrevious';
import { returnMultiSelectionData } from '../../../../shared/helpers/returnMultiSelectionData/returnMultiSelectionData';
import { returnAnalysisActionsItems } from '../../../../shared/helpers/returnAnalysisActionsItems/returnAnalysisActionsItems';
import { getUpdatedAnalysisOptions } from '../../../../shared/helpers/getUpdatedAnalysisOptions/getUpdatedAnalysisOptions';
import { LanguageSwitchButton } from '../../../../shared/helpers/languageSwitchButton/LanguageSwitchButton';
import { fetchPost as fetchCopyXML, fetchPostJson as updateToplineData } from '../../../../../../../../services/services';
import { returnBrowser } from '../../../../shared/helpers/returnBrowser/returnBrowser';
import { CopyXmlModal } from '../../CopyXmlModal/CopyXmlModal';
import { ExpandResultModal } from '../../ExpandResultModal/ExpandResultModal';
import { Icon } from '../../../../../../../shared/Icon/Icon';
import { DropdownButton } from '../../../../../../../shared/DropdownButton/DropdownButton';

export const DataTabContent = ({ onAutoSaveHandler, selectedTab, userData, datasetName, optionsData, expandedOptions, showOptionsModal, handleOptionsDialogClose, openOptionsDialog, user, rangeItems, analysisFunctionalities }) => {
  const { theData, languages, defaultLanguage, editingLanguage, showFullscreenResult } = useSelector(theState => (theState.setInitialDataReducer))
  const { state, undoQuestionOptions, redoQuestionOptions } = useSelector((theState) => (theState.rawDataStateReducer));
  const userSettings = useSelector(theState => theState.userSettingsReducer);
  const { token } = useSelector((state) => state.tokenStateReducer);

  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory()

  const projectId = params.name;
  const datasetId = params.dataset;
  const datasetType = params.analysisType;

  const zoomLevel = userSettings.zoomLevels.dataZoom;
  const [combineData, setCombineData] = useState([])
  const [showExportModal, setShowExportModal] = useState({ show: false, type: null, extension: null })
  const [showWeightWizard, setShowWeightWizard] = useState(false)
  const [showCopyXmlModal, setShowCopyXmlModal] = useState({ show: false, xmlCode: '' })

  const [errorMessage, setErrorMessage] = useState(null)
  const [didMount, setDidMount] = useState(true)
  const [checkedRows, setCheckedRows] = useState([])

  const prevUpdateTable = state ? usePrevious(state.updateTable) : null

  const updatedItems = returnUpdatedAnalysisActionItems(analysisFunctionalities, items, datasetType) //export dropdown
  const actionItems = returnAnalysisActionsItems(undoQuestionOptions, redoQuestionOptions, true, datasetType, !state.firstColumn.length) // actions dropdown

  const allColumnsDisabled = state.firstColumn.filter(e => !e.disabled).length === 0

  useEffect(() => {
    dispatch({ type: "RAW_DATA_SET_UPDATE_TABLE_STATE", payload: { status: true } })
  }, [dispatch])

  useEffect(() => {
    const checkedRowsFiltered = state.firstColumn.filter((item) => item.selected).map(el => el.id)
    if (didMount) {
      const checkedRowsNums = checkedRowsFiltered.map(e => ({ source: 'firstColumn', id: e }))
      dispatch({ type: 'RAW_DATA_UPDATE_CHECKED_NUM', payload: checkedRowsNums })
      setDidMount(false)
    }
    setCheckedRows(checkedRowsFiltered)
  }, [state.firstColumn, didMount, dispatch])

  useEffect(() => {
    if (state.updateTable && state.updateTable !== prevUpdateTable) {
      let dataUrl = `an/projects/${projectId}/analysis/${datasetId}`
      if (datasetType === 'surveys') {
        dataUrl = `an/projects/${projectId}/analysis/surveys/${datasetId}`
      }
      const body = returnUpdatedAnalysisBody(params.analysisType, 'data', state.newQuestionOptions, state.firstColumn, '', '', editingLanguage, defaultLanguage)
      updateToplineData(dataUrl, token, body)
        .then(res => {
          if (res?.error) {
            setErrorMessage(res.error)
            dispatch({ type: 'RAW_DATA_SET_TABLE_STATE', payload: { value: null, reducedTable: false } })
          } else {
            dispatch({ type: 'RAW_DATA_SET_TABLE_STATE', payload: { value: res.result, reducedTable: res.reducedTable } })
            setErrorMessage(null)
          }
        })
      onAutoSaveHandler(state)
    }
  }, [state, dispatch, datasetId, token, onAutoSaveHandler, prevUpdateTable, projectId, datasetType, editingLanguage, params.analysisType, defaultLanguage])

  const onSelectQuestion = (e, id) => {
    dispatch({ type: 'RAW_DATA_SELECT_QUESTION', payload: { id: id, theData: theData } })
  }

  const onCheckQuestion = (e, id, source, itemId, shouldDefineRange) => {
    const updatedRowsData = returnMultiSelectionData(state.firstColumn, checkedRows, shouldDefineRange, itemId, (data) => setCheckedRows(data), 'selected')
    dispatch({ type: 'RAW_DATA_UPDATE_ROWS', payload: updatedRowsData })
    setDidMount(true)
  }

  const clearQuestions = (props) => {
    if (props.item.action === "RAW_DATA_CLEAR_QUESTION_OPTIONS") {
      const dataValues = returnRawDataOptionsInitialValues(optionsData.data, user, optionsData.groups)
      const initialOptions = returnInitialOptions(defaultOptions[3].data, user, optionsData.groups, optionsData.data)
      dispatch({ type: props.item.action, payload: { values: dataValues, options: initialOptions } })

    } else if (props.item.action === "RAW_DATA_CLEAR_QUESTIONS") {
      const dataValues = returnRawDataOptionsInitialValues(optionsData.data, user, optionsData.groups)
      const initialOptions = returnInitialOptions(defaultOptions[3].data, user, optionsData.groups, optionsData.data)
      dispatch({ type: props.item.action, payload: { values: dataValues, options: initialOptions } })
    } else if (state.firstColumn.length > 0) {
      dispatch({ type: props.item.action })
      setErrorMessage(null)
    }
  }

  const handleOptionsUpdate = (newValues, values, data, option) => {
    const updatedQuestionOptions = Object.assign({}, state.newQuestionOptions, newValues)
    dispatch({ type: 'RAW_DATA_SET_QUESTION_OPTIONS', payload: { newValues: updatedQuestionOptions, values: values } })

    if (option === "Filter") {
      dispatch({ type: 'RAW_DATA_SET_FILTER_DATA', payload: { data: data } })
    }
    else if (option === "Universe") {
      dispatch({ type: 'RAW_DATA_SET_UNIVERSE_DATA', payload: { data: data } })
    }

    const updatedOptionsWithInitialValue = getUpdatedAnalysisOptions(state, values)
    dispatch({ type: 'UPDATE_RAW_DATA_UNDO_QUESTION_OPTIONS', payload: [...undoQuestionOptions, updatedOptionsWithInitialValue] })

    handleOptionsDialogClose()
  }

  const setCombineFilterData = (id) => {
    if (id === "Filter") {
      const filterDataCopy = [...state.filterData]
      setCombineData(filterDataCopy)
    } else {
      const universeDataCopy = [...state.universeData]
      setCombineData(universeDataCopy)
    }
  }

  const onOptionsClick = () => {
    const filterData = [...state.filterData];
    const universeData = [...state.universeData];
    const questionOptions = { ...state.questionOptions };
    const newQuestionOptions = { ...state.newQuestionOptions };

    dispatch({
      type: "SET_RAW_DATA_INITIAL_OPTIONS_DATA",
      payload: {
        initialFilterData: filterData,
        initialUniverseData: universeData,
        initialQuestionOptions: questionOptions,
        initialNewQuestionOptions: newQuestionOptions,
      }
    });

    openOptionsDialog();
  }

  const onActionHandler = (action) => {
    const updatedDataState = JSON.parse(JSON.stringify(state))
    const updatedDataQuestions = JSON.parse(JSON.stringify(updatedDataState.firstColumn))

    if (action === 'enable') {
      updatedDataQuestions.filter((item) => item.selected).forEach((el) => {
        el.disabled = false
      })
      updatedDataQuestions.forEach((item) => {
        item.selected = false
      })

      dispatch({ type: 'RAW_DATA_ENABLE_SELECTED_QUESTIONS', payload: updatedDataQuestions })
    } else if (action === 'disable') {
      updatedDataQuestions.filter((item) => item.selected).forEach((el) => {
        el.disabled = true
      })
      updatedDataQuestions.forEach((item) => {
        item.selected = false
      })
      dispatch({ type: 'RAW_DATA_DISABLE_SELECTED_QUESTIONS', payload: updatedDataQuestions })
    } else if (action === 'weight') {
      setShowWeightWizard(true)
    } else if (action === 'select rows') {
      const rows = state.firstColumn
      rows.map(e => e.selected = true)
      dispatch({ type: 'RAW_DATA_UPDATE_ROWS', payload: rows })
    } else if (action === 'deselect rows') {
      const rows = state.firstColumn
      rows.map(e => e.selected = false)
      dispatch({ type: 'RAW_DATA_UPDATE_ROWS', payload: rows })
    } else if (action === 'undo') {
      const updatedUndoQuestionOptions = [...undoQuestionOptions]
      const itemToAdd = undoQuestionOptions[undoQuestionOptions.length - 1]
      updatedUndoQuestionOptions.pop()
      dispatch({
        type: 'RAW_DATA_UNDO_LAST_CHANGE', payload: {
          questionOptions: { ...state.questionOptions, ...itemToAdd },
          undoQuestionOptions: updatedUndoQuestionOptions,
          redoQuestionOptions: [...redoQuestionOptions, state.questionOptions]
        }
      })
    } else if (action === 'redo') {
      const updatedRedoQuestionOptions = [...redoQuestionOptions]
      const itemToAdd = redoQuestionOptions[redoQuestionOptions.length - 1]
      updatedRedoQuestionOptions.pop()
      dispatch({
        type: 'RAW_DATA_REDO_LAST_CHANGE', payload: {
          questionOptions: { ...state.questionOptions, ...itemToAdd },
          redoQuestionOptions: updatedRedoQuestionOptions,
          undoQuestionOptions: [...undoQuestionOptions, state.questionOptions],
        }
      })
    } else if (action === 'copy xml') {
      let dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/${datasetId}/adoc`
      if (datasetType === 'surveys') {
        dataUrl = `an/projects/${history.location.pathname.split('/')[2]}/analysis/surveys/${datasetId}/adoc`
      }
      fetchCopyXML(dataUrl, token, returnUpdatedAnalysisBody(params.analysisType, 'data', state.newQuestionOptions, state.firstColumn))
        .then(res => {
          if (res && (res.message || res.error)) {
            setErrorMessage(res.error ? res.error : res.message)
          } else {
            res.text().then(data => {
              returnBrowser() === 'Safari' ?
                setShowCopyXmlModal({ show: true, xmlCode: data })
                :
                navigator.clipboard.writeText(data).then(() => {
                  dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'XML definition has been copied to clipboard' } })
                });
            })
            setErrorMessage(null)
          }
        })
    }
    setDidMount(true)
  }

  const exportFunc = (props) => {
    if (state.firstColumn && state.firstColumn.length > 0 && allColumnsDisabled === false) {
      setShowExportModal({ show: true, action: props.item.action, type: props.item.actionName, extension: props.item.extension })
    }
  }

  const actionItemRender = (item) => {
    return (
      <ExportActionItem
        user={user}
        props={{ item }}
        state={state}
        datasetId={datasetId}
        history={history}
        token={token}
        disabled={allColumnsDisabled}
        showExportModal={(e) => exportFunc(e)}
        tab='data' />
    )
  }

  const setLanguageError = (err) => {
    setErrorMessage(err)
  }

  return (
    <React.Fragment>
      {showOptionsModal &&
        <OptionsDialog
          tab='data'
          activeGroup={optionsData.selectedGroup}
          data={optionsData.data}
          dropdownValues={optionsData.dropdownValues}
          values={state.questionOptions}
          updateValues={state.newQuestionOptions}
          expandedOptions={expandedOptions}
          optionGroups={optionsData.groups}
          handleClose={handleOptionsDialogClose}
          handleUpdate={handleOptionsUpdate}
          setCombineFilterData={setCombineFilterData}
          userData={userData}
          datasetId={datasetId}
          combineFilterData={combineData}
          user={user}
          token={token}
          analysisType={datasetType}
          optionId={state.optionId}
          initialOptionsData={state.initialOptionsData}
          initialAndUpdateOptionsValues={state.initialAndUpdateOptionsValues}
        />
      }
      {
        showExportModal?.show &&
        <ExportModal
          onHide={() => { setShowExportModal(false); dispatch({ type: 'CLOSE_EXPORT_MODAL' }) }}
          showExportModal={showExportModal}
          options={{ exportOptions: optionsData.exportOptions, values: optionsData.dropdownValues }}
          onExportHandler={returnActionsData}
          state={state}
          updatedLanguage={editingLanguage}
          defaultLanguage={defaultLanguage}
          projectType={params.analysisType}
          datasetId={datasetId}
          history={history}
          tab={'data'}
          datasetName={datasetName}
          datasetType={datasetType}
          token={token} />
      }
      {showWeightWizard &&
        <WeightWizard
          handleClose={() => setShowWeightWizard(false)}
          token={token}
          datasetId={datasetId}
          user={user}

        />
      }
      {
        (user.plan === 'basic' || user.plan === 'essential_yearly' || user.plan === 'essential_monthly') && !user.isSubAccount ?
          <UpgradePlanMessage className="tab-overlay" user={user} />
          : null
      }
      {showCopyXmlModal.show && <CopyXmlModal xmlCode={showCopyXmlModal.xmlCode} onHide={() => setShowCopyXmlModal({ show: false, xmlCode: '' })} />}
      <div className={`d-flex flex-column overflow-hidden flex-fill ${(user.plan === 'basic' || user.plan === 'essential_yearly' || user.plan === 'essential_monthly') && !user.isSubAccount ? 'blur' : null}`}>
        <div className={"justify-content-between flex-wrap d-flex border-left pr-1"}>
          <div className="btn-group m-2 analysis-actions">
            <div className="btn-group">
              <DropDownButton
                text={"Clear"}
                textField="actionName"
                icon="fas fa fa-caret-down"
                className='analyze-actions-button'
                buttonClass={"btn btn-outline-analyze rounded-right-0 d-flex flex-row-reverse px-2"}
                items={dataClearItems}
                onItemClick={(props) => clearQuestions(props)}
                itemRender={(props) => (
                  <div className="p-1 dropdown-button__clear--small">
                    <span>
                      {props.item.actionName}
                    </span>
                  </div>
                )}
              />
            </div>
            <div className="btn-group">
              <DropDownButton
                text={"Actions"}
                textField="actionName"
                icon="fas fa fa-caret-down"
                className='analyze-actions-button'
                buttonClass={"btn btn-outline-analyze rounded-0 d-flex flex-row-reverse px-2"}
                items={actionItems.filter(e => !e.actionName.includes("Columns"))}
                onItemClick={(e) => onActionHandler(e.item.action)}
                popupSettings={{ popupClass: 'actions-dropdown' }}
              />
            </div>
            <Button
              className="btn btn-outline-analyze px-2"
              onClick={onOptionsClick}>Options
            </Button>
            {languages.length > 1 ?
              <div className='btn-group' role='group'>
                <LanguageSwitchButton
                  languages={languages}
                  editingLanguage={editingLanguage}
                  defaultLanguage={defaultLanguage}
                  tabType={"data"}
                  newQuestionOptions={state.newQuestionOptions}
                  firstColumn={state.firstColumn}
                  setLanguageError={setLanguageError}
                  token={token}
                  requestUrl={datasetType === "surveys" ? `an/projects/${projectId}/analysis/surveys/${datasetId}` : `an/projects/${projectId}/analysis/${datasetId}`}
                  dispatchType={'RAW_DATA_SET_TABLE_STATE'}
                  dataType={updateToplineData}
                  analysisBodyType={"data"}
                  componentType={"data"}
                />
              </div> : null}
          </div>

          <div className="btn-group m-2 analysis-actions">
            <ZoomButtons
              userSettings={userSettings}
              zoomType={'dataZoom'}
            />
            <DropdownButton
              hideChevron={true}
              className='btn-outline-analyze strong px-2'
              items={updatedItems}
              renderItem={actionItemRender}
              renderMainButton={() => (
                <span style={{ fontFamily: "Walr Inter", fontSize: "13px", fontWeight: 600 }} className='user-info'>Export</span>
              )}
            />
            <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
              <button
                type='button'
                className="btn btn-outline-analyze btn-ic p-1"
                disabled={state?.firstColumn?.length === 0}
                title="Fullscreen"
                onClick={() => dispatch({ type: 'SET_SHOW_FULLSCREEN_RESULT', payload: true })}>
                <Icon type="expand" />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="w-100 d-flex overflow-hidden flex-fill border-table-area">
          <div className="w-100 d-flex rawData">
            <div className='d-flex flex-column'>
              <DroppableColumn onCheck={onCheckQuestion} firstColumn={true} state={state} onSelectQuestion={onSelectQuestion} userData={userData} tab="data" user={user} rangeItems={rangeItems} />
            </div>
            <div className="w-100 h-100 d-flex flex-column overflow-hidden bg-white" style={{ fontSize: `${((zoomLevel) * 0.875).toString()}rem` }}>
              {
                showFullscreenResult &&
                <ExpandResultModal
                  result={state.displayTable}
                  onHide={() => dispatch({ type: 'SET_SHOW_FULLSCREEN_RESULT', payload: false })}
                />
              }
              {
                !errorMessage ?
                  state.updateTable && state.firstColumn.length !== 0 ?
                    <InProgressOverlay theme="primary" type="fullscreen" />
                    :
                    <React.Fragment>
                      {state.reducedTable ?
                        <div className="alert alert-warning text-center m-4">
                          The displayed table is reduced due to its size. Export the table to get the full content.
                        </div>
                        : null
                      }
                      <JsxParser jsx={state.displayTable} />
                    </React.Fragment>
                  :
                  <ErrorMessage type="alert" errorMessage={errorMessage} />
              }
            </div>
          </div>
        </div>
      </div >
    </React.Fragment >
  )
}