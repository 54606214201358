import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage'
import { DropdownButton } from '../shared/DropdownButton/DropdownButton';
import { FullHeightSpinner } from '../shared/FullHeightSpinner/FullHeightSpinner';
import { FullHeightOverlaySpinner } from '../shared/FullHeightOverlaySpinner/FullHeightOverlaySpinner';
import { type Audience, AudienceStatus, type AudienceStatusType, type AudienceTabsType, type AudiencesData, type Projects } from '../../interfaces/auddineceAccessInterfaces/audienceTypes';
import { AudiencesHeader } from './AudiencesHeader';
import { AudiencesNoProjects } from './AudiencesNoProjects';
import { AudiencesNav } from './AudiencesNav';
import type { RootState } from '../../store/reducers/rootReducer';
import { fetchGetJson, fetchPutResOrJson } from '../../services/services';

export const Audiences = () => {
  document.title = "Audiences – Walr";
  const [selectedTab, setSelectedTab] = useState<AudienceTabsType>("All");
  const [isOverlayLoading, setIsOverlayLoading] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, message: '' })
  const [isLoading, setIsLoading] = useState(true)
  const [shouldFetchData, setShouldFetchData] = useState(false)
  const [expandProject, setExpandProject] = useState<string | undefined>(undefined)
  const [selectedMarket, setSelectedMarket] = useState<{
    name: string;
    isoCode: string;
  }>({ name: '', isoCode: '' })
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  const [isStatusLoading, setIsStatusLoading] = useState(false)
  const [audiencesData, setAudiencesData] = useState<AudiencesData>({
    continuationToken: null,
    statusCounts: [],
    projects: []
  })

  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);

  const dispatch = useDispatch()
  const statuses = AudienceStatus.map((status: AudienceStatusType) => ({ text: status }))
  const updateStatusInputClasses: { [key: string]: string } = {
    Pending: 'pending',
    Live: 'live',
    Closed: 'default',
    Cancelled: 'default',
    Reconciled: 'default',
    Archived: 'default',
    Draft: 'default'
  }
  const projectHeaders = ["Audience name", "Project manager", "Progress", "CPI", "IR", "LOI", "Drop-off", "Quota failed"] as const;

  useEffect(() => {
    if (searchTerm !== null) {
      const fetchDataTimer = setTimeout(() => {
        setShouldFetchData(true)
      }, 1000)

      return () => clearTimeout(fetchDataTimer)
    }
  }, [searchTerm])

  useEffect(() => {
    if (shouldFetchData) {
      setIsLoading(true)
      setShouldFetchData(false)
      fetchGetJson(`au/a/audiences?status=${selectedTab === 'All' ? '' : selectedTab}&${searchTerm && `searchTerm=${searchTerm}`}${selectedMarket.isoCode && `&country=${selectedMarket.isoCode}`}`, token, undefined, customerId).then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
        } else {
          setAudiencesData(res)
        }
        setIsLoading(false);
        setIsOverlayLoading(false);
      })
    }
  }, [searchTerm, shouldFetchData, selectedMarket, token, dispatch, selectedTab, customerId])

  const fetchAudiencesData = (searchTermValue: string, selectedMarketValue: string, status: string, continuationToken?: string) => {
    setIsLoading(true)
    let fetchUrl = ""
    if (searchTermValue === "All markets") {
      fetchUrl = "au/a/audiences"
    } else {
      fetchUrl = `au/a/audiences?status=${status === 'All' ? '' : status}${searchTermValue && `&searchTerm=${searchTermValue}`}${selectedMarketValue && `&country=${selectedMarketValue}`}${continuationToken ? `&continuationToken=${continuationToken}` : ''}`
    }
    fetchGetJson(fetchUrl, token, undefined, customerId).then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
      } else {
        if (continuationToken) {
          setAudiencesData((prevState) => ({ ...res, projects: [...prevState.projects, ...res.projects] }))
        } else {
          setAudiencesData(res)
        }
      }
      setIsLoading(false)
      setIsOverlayLoading(false);
    })
  }

  const updateAudienceStatus = (status: string, projectId: string, audienceId: string, etag: string) => {
    fetchPutResOrJson(`au/a/projects/${projectId}/audiences/${audienceId}/status`, token, { audienceStatus: status, etag: etag }, undefined, customerId)
      .then(async (res: TODO) => {
        setIsStatusLoading(false)
        if (res.ok) {
          const { etag } = await res.json()
          const updatedProjects: TODO = audiencesData.projects.map((project: Projects) =>
            project.id === projectId ?
              {
                ...project,
                audiences: project.audiences.map((audience: Audience) => audience.audienceId === audienceId ?
                  {
                    ...audience,
                    etag,
                    status
                  }
                  :
                  audience)
              }
              :
              project)
          setAudiencesData(prevState => ({
            ...prevState,
            projects: updatedProjects
          }))
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Status updated successfully' } })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.status !== 200 ? res.title : res.message } });
        }
      })
  }

  return (
    <section className="main audiences-container p-0">
      <div className='d-flex flex-column w-100 projects'>
        <AudiencesHeader
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          setSelectedMarket={setSelectedMarket}
          selectedMarket={selectedMarket}
          selectedTab={selectedTab}
          fetchAudiencesData={fetchAudiencesData}
          setAudiencesData={setAudiencesData}
          setIsLoading={setIsLoading}
          setIsOverlayLoading={setIsOverlayLoading}
        />

        <div className='d-flex px-6 py-5 w-100'>
          <div className='nav-board d-flex justify-content-center flex-column w-100 bg-white'>
            <AudiencesNav
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setIsOverlayLoading={setIsOverlayLoading}
              isLoading={isLoading}
              audiencesData={audiencesData}
              searchTerm={searchTerm}
              selectedMarket={selectedMarket}
              fetchAudiencesData={fetchAudiencesData}
            />

            <div className="px-3 pb-3 position-relative h-100">
              {isOverlayLoading && (<FullHeightOverlaySpinner />)}

              <div className='project-header mt-3 bg-white'>
                <div className='inner mx-0 row py-2 text-primary strong'>
                  {projectHeaders.map((tab, index) => (
                    <span
                      key={index}
                      className={`${index === 0 || index === 8 ? 'col-2' : 'col'} text-nowrap`}>
                      {tab}
                    </span>
                  ))}
                </div>
              </div>
              {!audiencesData?.projects?.length && isLoading && (
                <div className='d-flex w-100 border-bottom project-row' style={{ height: "90svh" }}>
                  <FullHeightSpinner />
                </div>
              )}
              {audiencesData.projects?.length ? audiencesData.projects.map((project: Projects, index: number) => (
                <Fragment key={index}>
                  <div onClick={() => setExpandProject(expandProject === project.id ? undefined : project.id)} className='d-flex w-100 border-bottom project-row'>
                    <div
                      className="col-2 d-flex flex-column w-min-300">
                      <span className="mb-1 text-nowrap text-truncate strong">
                        <Link to={`/projects/${project.id}`}>
                          {project.projectName}
                        </Link>
                        <span className='text-muted normal ml-1'>
                          {project.audiences.length && `(${project.audiences.length})`}
                        </span>
                      </span>
                      <div className="d-flex flex-wrap text-nowrap text-truncate">
                        {project.markets.map((market: string, index: number) => <span key={index} className="d-flex align-items-center text-nowrap text-truncate small strong h-24 px-2 badge m-05">
                          {market}
                        </span>)}
                      </div>
                    </div>
                    <span className='d-flex col text-nowrap align-items-center text-truncate  w-min-250 text-empty'>
                      -
                    </span>
                    <div
                      className="col d-flex flex-column w-100 text-nowrap align-items-center justify-content-center text-truncate w-min-250">
                      <div className='w-100 mt-1'>
                        {project.progress?.current}/{project.progress?.total}
                        <span className='text-muted'>
                          {`(${Math.floor(project.progress?.percentage)}%)`}
                        </span>
                      </div>
                      <div className="progress w-100 mt-2" style={{ height: "6px" }}>
                        <div className="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: project.progress?.percentage }} aria-valuenow={project.progress?.percentage} aria-valuemin={0} aria-valuemax={100} />
                      </div>
                    </div>
                    <span className="d-flex col text-nowrap align-items-center text-truncate">
                      £{project.averageCPI?.toFixed(2)}
                    </span>
                    <span className="d-flex col text-nowrap align-items-center text-truncate">
                      {Number(project.incidenceRateStats?.actual) ? `${Math.round(project.incidenceRateStats?.actual)}%` : <span className='text-empty'>-</span>}
                      {
                        Number(project.incidenceRateStats?.difference) ?
                          <span className='text-muted ml-1'>{`(${Math.round(project.incidenceRateStats?.difference)}%)`} </span>
                          :
                          null
                      }
                    </span>
                    <span
                      className="d-flex col text-nowrap align-items-center text-truncate">
                      {Number(project.loi?.actual) ? `${Math.round(project.loi?.actual)} min` : <span className='text-empty'>-</span>}
                      {
                        Number(project.loi?.difference) ?
                          <span className='text-muted ml-1'>{`(${Math.round(project.loi?.difference)} min)`} </span>
                          :
                          null
                      }
                    </span>
                    <span className="d-flex col text-nowrap align-items-center text-truncate">
                      {Number(project.averageDropOffPercentage) ? `${Math.round(project.averageDropOffPercentage)}%` : <span className='text-empty'>-</span>}
                    </span>
                    <span className="d-flex col text-nowrap align-items-center text-truncate">
                      {Number(project.quotaFailedPercentage) ? `${Math.round(project.quotaFailedPercentage)}%` : <span className='text-empty'>-</span>}
                    </span>
                  </div>
                  {
                    project.audiences.length && expandProject === project.id && project.audiences.map((audience: Audience, idx: number) =>
                      <div key={idx} className='m-0 row project-sub-row'>
                        <div
                          className="col-2 d-flex flex-column w-min-300">
                          <span className="text-nowrap text-truncate mr-2 mb-1">
                            <Link className='strong' to={customerId ? `/projects/${project.id}/audiences/${audience.audienceId}?cid=${customerId}` : `/projects/${project.id}/audiences/${audience.audienceId}`}>
                              {audience.name}
                            </Link>
                          </span>
                          <span className='d-flex flex-row'>
                            <DropdownButton
                              items={statuses}
                              className={`btn btn-shadow small h-24 btn-icon icon-r ${updateStatusInputClasses[audience.status]}`}
                              text={audience.status}
                              disabled={isStatusLoading}
                              onItemClick={(e) => {
                                setIsStatusLoading(true);
                                updateAudienceStatus(e.item.text, project.id, audience.audienceId, audience.etag)
                              }}
                            />
                            {
                              audience.market && <span className="d-flex align-items-center text-muted border bg-white text-nowrap text-truncate small strong h-24 px-2 badge ml-1">{audience.market}</span>
                            }
                          </span>
                        </div>
                        <span className="d-flex col text-nowrap align-items-center text-truncate  w-min-250">{audience.projectManagerEmail}</span>
                        <span
                          className="col text-nowrap align-items-center text-truncate w-min-250">
                          <div className='mt-1'>{audience.progress?.current}/{audience.progress?.total} <span className='text-muted'>{`(${Math.floor(audience.progress?.percentage)}%)`}</span></div>
                          <div className="progress mt-2" style={{ height: "6px" }}>
                            <div className="progress-bar" role="progressbar" aria-label="Basic example" style={{ width: audience.progress?.percentage }} aria-valuenow={audience.progress?.percentage} aria-valuemin={0} aria-valuemax={100} />
                          </div>
                        </span>
                        <span
                          className="d-flex col text-nowrap align-items-center text-truncate">£{audience.cpi?.toFixed(2)}</span>
                        <span
                          className="d-flex col text-nowrap align-items-center text-truncate">

                          {Number(audience.incidenceRateStats?.actual) ? `${Math.round(audience.incidenceRateStats?.actual)}%` : <span className='text-empty'>-</span>}
                          {
                            Number(audience.incidenceRateStats?.difference) ?
                              <span className='text-muted ml-1'>{`(${Math.round(audience.incidenceRateStats?.difference)}%)`} </span>
                              :
                              null
                          }</span>
                        <span
                          className="d-flex col text-nowrap align-items-center text-truncate">
                          {Number(audience.loi?.actual) ? `${Math.round(audience.loi?.actual)} min` : <span className='text-empty'>-</span>}
                          {
                            Number(audience.loi?.difference) ?
                              <span className='text-muted ml-1'>{`(${Math.round(audience.loi?.difference)} min)`} </span>
                              :
                              null
                          }
                        </span>
                        <span className="d-flex col text-nowrap align-items-center text-truncate">
                          {Number(audience.dropOffPercentage) ? `${Math.round(audience.dropOffPercentage)}%` : <span className='text-empty'>-</span>}
                        </span>
                        <span className="d-flex col text-nowrap align-items-center text-truncate">
                          {Number(audience.quotaFailedPercentage) ? `${Math.round(audience.quotaFailedPercentage)}%` : <span className='text-empty'>-§</span>}
                        </span>
                      </div>)
                  }
                </Fragment>
              )) : <AudiencesNoProjects searchTerm={searchTerm} selectedMarket={selectedMarket} />}
            </div>

            {audiencesData.continuationToken ? <div className='d-flex justify-content-center w-100 border-top'>
              <button
                onClick={() => {
                  fetchAudiencesData(searchTerm ? searchTerm : '', selectedMarket.isoCode, selectedTab, audiencesData.continuationToken!)
                  setIsOverlayLoading(true);
                }}
                className="btn btn-shadow my-4" type="button">
                Load more
              </button>
            </div> : null}
          </div>
        </div >
        {
          errorModal.show &&
          <ErrorMessage
            type="modal"
            errorMessage={errorModal.message}
            onHide={() => setErrorModal({ show: false, message: '' })}
          />
        }
      </div >
    </section>
  )
}