import { useSelector } from 'react-redux';

import type { RootState } from '../../../../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import { InProgressOverlay } from '../../../../../shared/InProgressOverlay/InProgressOverlay';
import { TabContent } from './TabContent/TabContent';

interface Props {
  handleEditTool: () => void
}

export const LogicTabContent = ({ handleEditTool }: Props) => {
  const { workflowData } = useSelector((theState: RootState) => (theState.workflowStateReducer));

  return (
    <div className="h-100" style={workflowData.selectedTool.isDisabled ? { pointerEvents: 'none', opacity: '50%' } : undefined}>
      {workflowData.loadingTool && workflowData.loadingToolType === 'load' ?
        <FullHeightSpinner />
        : workflowData.selectedTool ?
          <div className="d-flex flex-column h-100">
            {/* <div className="w-100 h-100"> */}
            {workflowData.loadingTool && workflowData.loadingToolType === 'save' &&
              <InProgressOverlay type="overlay" theme="primary" message='Saving' />
            }
            <TabContent
              isCustomTool={workflowData.selectedTool.isCustom}
              toolType={workflowData.selectedTool.toolType}
              handleEditTool={handleEditTool}
            />
            {/* </div> */}
          </div>
          :
          null}
    </div>
  )
}