import { Fragment, useState } from "react";
import { useSelector } from "react-redux";

import FileManager from "../../../shared/FileManager/FileManager";
import type { RootState } from "../../../../store/reducers/rootReducer";
import GroupSettings from "../../../Survey/SurveyTabContent/SurveyDesignTabContent/helpers/GroupSettings/GroupSettings";

type Props = {
  options: TODO;
  groups: TODO;
  setOptions: (data: TODO) => void
}

export const WorkflowToolOptionsTabContent = ({ options, groups, setOptions }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [showFileManager, setShowFileManager] = useState({ show: false, setting: '' });

  const onChangeSettingsValues = (value: TODO, setting: string, _type: string) => {
    const updatedOptions = [...options];
    updatedOptions.find((option: TODO) => option.id === setting).value = value;
    setOptions(updatedOptions);
  }

  if (options?.length > 0) {
    return (
      <Fragment>
        {
          showFileManager.show &&
          <FileManager
            token={token}
            type="public"
            displayType="modal"
            onSelect={(value) => console.log(value)}
            onHide={() => setShowFileManager({ show: false, setting: '' })}
          />
        }
        <GroupSettings
          fonts={[]}
          groups={groups}
          settings={options}
          dropdownValues={[]}
          type="visualisation"
          settingsValues={options}
          dropdownPopupSettings={undefined}
          onChangeSettingsValues={onChangeSettingsValues}
          onShowFileManager={(id: string) => setShowFileManager({ show: true, setting: id })}
        />
      </Fragment>
    )
  }
  return (
    <div className="d-flex flex-column align-items-center my-5 gap-md">
      <i className="fal fa-folder-open fa-2x text-muted" />
      <p className="m-0 text-center text-muted">No options found</p>
    </div>
  )
}