export const returnChartScaleData = (scales: number[], stacked: boolean, type: string) => {
  const values = {
    min: 0,
    max: 0,
    scale: 0
  }

  if (scales && scales.length !== 0) {
    if (stacked === true && scales[0] === 0 && scales[scales.length - 1] === 100 && type !== 'profile' && type !== 'profilemid') {
      values.min = 0
      values.max = scales[scales.length - 1]
      values.scale = (scales[1] - scales[0]) * 2
    }
    else if (type === 'profile') {
      values.min = (scales[0]) * 2
      values.max = (scales[scales.length - 1]) * 2
      values.scale = (scales[1] - scales[0]) * 2
    }
    else if (type === 'profilemid') {
      values.max = (scales[scales.length - 1])
      values.min = values.max * -1
      values.scale = (scales[1] - scales[0])
    }
    else {
      values.min = scales[0]
      values.max = scales[scales.length - 1]
      values.scale = scales[1] - scales[0]

    }
  }
  return values
}