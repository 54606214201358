import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tooltip } from '@progress/kendo-react-tooltip';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ToolCard } from './ToolCard/ToolCard';
import { Icon } from '../../../shared/Icon/Icon';
import type { RootState } from '../../../../store/reducers/rootReducer'
import { RunButtonModal } from './helpers/runButtonModal/runButtonModal';
import { WorkflowDesignTabs } from './WorkflowDesignTabs/WorkflowDesignTabs'
import { AddNewToolModal } from '../../shared/AddNewToolModal/AddNewToolModal'
import { FullHeightSpinner } from '../../../shared/FullHeightSpinner/FullHeightSpinner'
import type { ToolReference } from '../../../../interfaces/workflowInterfaces/toolInterfaces';
import { fetchGetJson as getWorkflow, fetchGetJson as getTool, fetchPost as postWorkflow } from '../../../../services/services'

const reorder = (list: [], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const WorkflowDesignTabContent = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);
  const [workflowDataArr, setWorkflowDataArr] = useState(workflowData.data?.toolReferences?.length ?
    workflowData.data.toolReferences : [])
  const [toolSelected, setToolSelected] = useState<string | null>(null)
  const [showModal, setShowModal] = useState<{ show: boolean, editTool: boolean }>({
    show: false,
    editTool: false
  })
  const [showRunButtonModal, setShowRunButtonModal] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (workflowData.data.toolReferences.length) {
      setWorkflowDataArr(workflowData.data.toolReferences);
    } else {
      setWorkflowDataArr([]);
    }
  }, [workflowData.data.toolReferences])

  useEffect(() => {
    if (workflowData.selectedTool) {
      const updatedWorkflowDataArr = workflowDataArr;
      const workflowDataItem = updatedWorkflowDataArr.find((item: ToolReference) => item.referenceId === workflowData.selectedTool.id);
      if (workflowDataItem) {
        workflowDataItem.referenceDisplayName = workflowData.selectedTool.name;
      }
      setWorkflowDataArr(updatedWorkflowDataArr);
    }
  }, [workflowData.selectedTool, workflowDataArr])

  const onDragEnd = (result: TODO) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      workflowDataArr,
      result.source.index,
      result.destination.index
    );
    setWorkflowDataArr(items)
  }

  const onSelectTool = (id: string) => {
    if (!workflowData.selectedTool || workflowData.selectedTool.id !== id) {
      dispatch({ type: 'LOADING_TOOL', payload: 'load' });
      getTool(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}/tools/${id}`, token)
        .then((res: TODO) => {
          if (res) {
            dispatch({ type: 'SET_TOOL_SELECTED', payload: { ...res, state: typeof res.state === 'string' ? JSON.parse(res.state) : null } })
            onCheckTool(id)
          }
        })
    }
  }

  const updateData = () => {
    getWorkflow(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}`, token)
      .then((res: TODO) => {
        dispatch({ type: 'UPDATE_WORKFLOW_DATA', payload: res })
        dispatch({ type: 'LOADING_TOOL', payload: 'load' });
        if (res?.toolReferences.length) {
          onSelectTool(res.toolReferences[res.toolReferences.length - 1].referenceId)
        }
      })
  }

  const onCheckTool = (id: string) => {
    if (toolSelected !== id) {
      setToolSelected(id)
    } else {
      setToolSelected(null)
    }
  }

  const runWorkflow = () => {
    postWorkflow(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}`, token)
      .then(() => {
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Processing workflow...' } })
        setShowRunButtonModal(false);
      })
  }

  return (
    <div className="d-flex flex-column h-100 p-0">
      {showModal.show &&
        <AddNewToolModal
          projectId={workflowData.data.projectId}
          workflowId={workflowData.data.id}
          token={token}
          handleClose={() => setShowModal({ show: false, editTool: false })}
          updateData={updateData}
          editMode={showModal.editTool}
        />}
      {showRunButtonModal &&
        <RunButtonModal
          token={token}
          handleClose={() => setShowRunButtonModal(false)}
          runWorkflow={runWorkflow}
        />}
      <div className="d-flex bg-white h-100">
        <div className="row no-gutters flex-grow-1 h-100 w-100 overflow-hidden">
          <div className="col-lg-3 h-100">
            <div className="workflow-list-header d-flex align-items-center justify-content-between p-3 h-48 border-right">
              <h6 className='strong mb-0 lh-24'>Workflow</h6>
              <Tooltip anchorElement="target" position="bottom">
                <button type='button' className="btn btn-icon btn-transparent p-0" onClick={() => setShowModal(prevState => ({ ...prevState, show: true }))} title="Add new tool" ><Icon type="add" className="pe-none" /></button>
              </Tooltip>
            </div>
            <div className="workflow-list d-flex w-100 h-100 border-right">
              {workflowData.loading ?
                <FullHeightSpinner /> :
                workflowDataArr.length > 0 ?
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          className="table"
                          ref={provided.innerRef}>
                          {workflowDataArr.map((item: TODO, key: number) => (
                            <Draggable key={item.referenceId} draggableId={item.referenceId} index={key}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps} className={`d-flex align-items-center item ${workflowData.selectedTool && workflowData.selectedTool.id === item.referenceId ? 'selected' : ''} ${snapshot.isDragging ? 'dragging' : ''}`}
                                  key={item.referenceId}>
                                  <ToolCard
                                    token={token}
                                    item={item}
                                    onSelectTool={onSelectTool}
                                    toolSelected={toolSelected}
                                    setToolSelected={setToolSelected}
                                    updateData={updateData} />
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext> :
                  <div>
                  </div>}
              {!workflowDataArr.length &&
                <div className="d-flex flex-column h-100 w-100 justify-content-center align-items-center">
                  <span className="droppable-text strong">No tools added yet</span>
                </div>
              }
            </div>
          </div>
          <div style={{ height: 'calc(100vh - 54px)' }} className="col-lg-9">
            <WorkflowDesignTabs
              handleEditTool={() => setShowModal({ show: true, editTool: true })}
            />
          </div>
        </div>
      </div>
    </div >
  )
}