import { useDispatch, useSelector } from 'react-redux';

import type { RootState } from '../../../../../../store/reducers/rootReducer';

interface Props {
  text: TODO;
  itemIndex: number
  showLogic: boolean
  itemTextIndex?: number
  type: "stext" | "r" | "h" | "qtext"
  showFilterBuilderHandler: (option: string, type: string, itemIndex: number, itemTextIndex?: number, expression?: string) => void
}

export const TextFilterDropdownButton = ({ text, showLogic, type, itemIndex, itemTextIndex, showFilterBuilderHandler }: Props) => {
  const dispatch = useDispatch()
  const { theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))

  const onRemoveTextFilter = (type: string, itemIndex: number, itemTextIndex?: number) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (type === 'stext') {
      updatedSelectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].elements.find((el: { type: string }) => el.type === 'stext').elements[itemIndex].attributes.filter = ""
    } else if (type === 'r') {
      if (typeof itemTextIndex === 'number') {
        updatedSelectedItem.elements.find((el: { type: string }) => el.type === 'rgroup').elements[itemIndex].elements[itemTextIndex].attributes.filter = ""
      }
    } else if (type === 'h') {
      if (typeof itemTextIndex === 'number') {
        updatedSelectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].elements.find((el: { type: string }) => el.type === 'hgroup').elements[itemIndex].elements[itemTextIndex].attributes.filter = ""
      }
    } else if (type === 'infoText') {
      updatedSelectedItem.elements[itemIndex].attributes.filter = ""
    } else if (type === 'qtext') {
      updatedSelectedItem.elements.find((el: { type: string }) => el.type === 'qtext').elements[itemIndex].attributes.filter = ""
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    dispatch({ type: 'SAVE_QUESTION' })
  }

  return (
    <div className="dropdown">
      <button
        type='button'
        aria-haspopup="true"
        aria-expanded="false"
        data-toggle="dropdown"
        title={text.attributes?.filter && showLogic ? `${text.attributes.filter}` : ""}
        className={`btn ${text.attributes?.filter && showLogic ? "btn-survey-light-blue text-survey-dark-blue" : "btn-secondary"} py-1 px-2 filter`}>F
      </button>
      <div className="dropdown-menu border-0">
        <button onClick={() => showFilterBuilderHandler('filter', type, itemIndex, itemTextIndex, text.attributes?.filter)} className="dropdown-item" type="button">{text.attributes?.filter && showLogic ? "Edit" : "Add"} filter</button>
        {
          text.attributes?.filter && showLogic ?
            <button onClick={() => onRemoveTextFilter(type, itemIndex, itemTextIndex)} className="dropdown-item" type="button">Remove filter</button>
            : null
        }
      </div>
    </div>
  )
}