import React from 'react';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxisItem,
  ChartValueAxis,
} from '@progress/kendo-react-charts';
import returnLabelContent from '../../../../../../shared/helpers/returnLabelContent/returnLabelContent';
import { returnChartScaleData } from '../../../../../../shared/helpers/returnChartScaleData/returnChartScaleData';

export default ({ data, zoomLevel }) => {
  const categories = data[0].categories
  const series = []
  const negateSeries = []

  data[0].series.forEach((el, key) => {
    if (key !== 0) {
      if (key % 2 !== 0) {
        let negateValues = []
        el.data.forEach(value => {
          negateValues.push(value * -1)
        })
        negateSeries.push({ name: el.name, data: negateValues })
      }
      else {
        series.push({ name: el.name, data: el.data })
      }
    }
  })

  const firstItem = data[0].series[0]

  const values = returnChartScaleData(data[0].scale, data[0].isstacked, data[0].type)

  return (
    <React.Fragment>
      <span className="table-chart-title" style={{ "fontSize": `${((zoomLevel) * 0.875).toString()}rem` }}>{data[0].title}</span>
      <Chart transitions={false} style={{ "minHeight": `${800 * (zoomLevel)}px` }} >
        <ChartLegend position="right" orientation="vertical" labels={{ font: `${zoomLevel}rem sans-serif` }} />
        <ChartValueAxis>
          <ChartValueAxisItem
            min={values.max * -1}
            max={values.min * -1}
            majorUnit={values.scale}
            axisCrossingValue={values.max * -1}
            labels={{ content: (e) => returnLabelContent(e, data[0].hidepctsign), rotation: "auto", font: `${zoomLevel}rem sans-serif` }}
            name="first" />
          <ChartValueAxisItem
            min={values.min}
            max={values.max}
            majorUnit={values.scale}
            axisCrossingValue={values.min}
            labels={{ content: (e) => returnLabelContent(e, data[0].hidepctsign), rotation: "auto", font: `${zoomLevel}rem sans-serif` }}
            name="second" />
        </ChartValueAxis>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories} axisCrossingValues={[0, `${categories.length}`]} labels={{ rotation: "auto", font: `${zoomLevel}rem sans-serif` }} />
        </ChartCategoryAxis>
        <ChartSeries visible={true}>
          <ChartSeriesItem
            type="bar" gap={4}
            spacing={0.5}
            stack={true}
            data={firstItem.data}
            name={firstItem.name}
            tooltip={{ visible: true }}
            labels={{ visible: data[0].hidevalues ? false : true, content: (e) => returnLabelContent(e, data[0].hidepctsign), font: `${zoomLevel}rem sans-serif` }}
            axis='second' />
          {
            series.map((item, key) => (
              <ChartSeriesItem
                type="bar"
                spacing={0.5}
                key={key}
                data={item.data}
                name={series.length < 25 ? item.name : null}
                tooltip={{ visible: true }}
                labels={{ visible: data[0].hidevalues ? false : true, content: (e) => returnLabelContent(e, data[0].hidepctsign), font: `${zoomLevel}rem sans-serif` }}
                axis='second'
              />
            ))
          }
          {
            negateSeries.map((item, key) => (
              <ChartSeriesItem
                type="bar"
                spacing={0.5}
                key={key}
                data={item.data}
                name={series.length < 25 ? item.name : null}
                tooltip={{ visible: data[0].hidepctsign ? false : true }}
                axis='first'
                labels={{ visible: data[0].hidevalues ? false : true, font: `${zoomLevel}rem sans-serif` }}
                reversed={true}
              />
            ))
          }
        </ChartSeries>
      </ Chart>
    </React.Fragment>
  )
}