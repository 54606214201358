import { Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "../../../../../../../shared/Icon/Icon";
import type { RootState } from "../../../../../../../../store/reducers/rootReducer";
import { FullHeightSpinner } from "../../../../../../../shared/FullHeightSpinner/FullHeightSpinner";
import { CustomToolTabContentIframe } from "./CustomToolTabContentIframe/CustomToolTabContentIframe";
import { fetchGetJson } from "../../../../../../../../services/services";

type Props = {
  handleEditTool: () => void
}

export const CustomToolTabContent = memo(({ handleEditTool }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);
  const [dataForIframe, setDataForIframe] = useState({ inputStructure: null, filter: "", props: {}, state: {} });
  const [iframeUrl, setIframeUrl] = useState("");
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    if (workflowData.selectedTool && !didMount) {
      setDidMount(true);
      const { projectId, datasetId, inputIsSurveyDataset, preFilter } = workflowData.selectedTool.input;
      if (projectId && datasetId) {
        const structureUrl = inputIsSurveyDataset ? `an/projects/${projectId}/analysis/surveys/${datasetId}/structure` : `an/projects/${projectId}/analysis/${datasetId}/structure`;
        fetchGetJson(structureUrl, token)
          .then((res: TODO) => {
            if (!res.error && !res.messsage) {
              setIframeUrl(workflowData.selectedTool.htmlUrl);
              setDataForIframe({ inputStructure: res, filter: preFilter, props: workflowData.selectedTool.props || [], state: workflowData.selectedTool.state || {} });
            } else {
              dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.message || res.error } });
            }
          })
      } else {
        setIframeUrl(workflowData.selectedTool.htmlUrl);
        setDataForIframe({ inputStructure: null, filter: "", props: workflowData.selectedTool.props || [], state: workflowData.selectedTool.state || {} });
      }
    }
  }, [dispatch, token, didMount, workflowData.selectedTool]);

  return (
    <Fragment>
      <div className="d-flex align-items-center workflow-tool-header h-48 border-bottom">
        <div className="d-flex justify-content-between align-items-center w-100">
          <h6 className="mb-0 strong mr-2">{workflowData?.selectedTool?.name}</h6>
          <button type='button' onClick={handleEditTool} className="btn btn-transparent btn-icon icon-l">
            <Icon type="dashboard-edit" className='mr-1 fill-primary' />
            Edit tool
          </button>
        </div>
      </div>
      <div style={{ flex: '1 1 auto' }} className="d-flex">
        <div className="d-flex flex-column h-100 w-100 p-4">
          {
            iframeUrl && iframeUrl !== "" ?
              <CustomToolTabContentIframe
                iframeUrl={iframeUrl}
                dataForIframe={dataForIframe}
              />
              :
              <FullHeightSpinner />
          }
        </div>
      </div>
    </Fragment>
  )
});