import { FullHeightSpinner } from "../../../../../../../shared/FullHeightSpinner/FullHeightSpinner";

interface StepSixProps {
  weightResult?: WeightResult;
  isLoading: boolean;
}

const OutPutDefinitionCell = ({ title, description }: { title: string; description: string }) => {
  return (
    <>
      <div className='font-weight-bold text-left'>{title}</div>
      <div className='text-left text-muted font-italic'>{description}</div>
    </>
  );
};

const StepSix = ({ weightResult, isLoading }: StepSixProps) => {

  if (!weightResult?.overallStats) {
    return <div>No data available.</div>;
  }

  const { overallStats, iterations, converged } = weightResult;

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <FullHeightSpinner />
        </div>
      ) : (<div className="container mt-4 advanced-weight-result">
        <table className="table">
          <thead className="text-align-center">
            <tr className='bg-info text-white'>
              <th>Output and Definition </th>
              <th>Outcome</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <OutPutDefinitionCell title='Weight Values' description='The final calculated weights, provided as decimal numbers' />
              </td>
              <td className='text-muted font-italic'>{'To view this, please fo to the Data > Options > Inclued weight values'}</td>
            </tr>
            <tr>
              <td><OutPutDefinitionCell title='Number of Iterations' description='The total number of iterations performed. (Max 20)' /></td>
              <td>{iterations}</td>
            </tr>
            <tr>
              <td><OutPutDefinitionCell title='Converged' description='True or False value indicating whether the errorlimit was achieved within the specified maximum number of iterations. If True, the accepted error limit within max iterations was reached.' /></td>
              <td>{converged.toString().toUpperCase()}</td>
            </tr>
            <tr>
              <td><OutPutDefinitionCell title='Efficiency' description='A measure of how efficiently the weights were adjusted to meet the target values.' /></td>
              <td>{overallStats?.eff}</td>
            </tr>
            <tr>
              <td><OutPutDefinitionCell title='Minimum Weight' description='The smallest calculated weight among all respondents.' /></td>
              <td>{overallStats?.min}</td>
            </tr>
            <tr>
              <td><OutPutDefinitionCell title='Maximum Weight' description='The largest calculated weight among all respondents.' /></td>
              <td>{overallStats?.max}</td>
            </tr>
            <tr>
              <td><OutPutDefinitionCell title='Weight Sum' description='The total sum of all weight values after the process is completed.' /></td>
              <td>{overallStats?.sum}</td>
            </tr>
          </tbody>
        </table>
      </div>)}
    </>);
};

export default StepSix;
