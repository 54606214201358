import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../../shared/Icon/Icon';
import type { RootState } from '../../../../store/reducers/rootReducer';
import type { CustomDataOpsData } from '../../../../interfaces/customDataOpsInterface/customDataOpsInterface';
import { updateCustomDataOpsData, updateCustomDataOpsSelectedItem, updateCustomDataOpsShowCreateEditModal } from '../../../../store/reducers/customDataOpsStateReducer/customDataOpsStateReducer';
import { fetchPatchJson } from '../../../../services/services';

interface Props {
  selectedItem: CustomDataOpsData;
  activeViewTab: "Edit" | "Preview";
  viewTabs: readonly ["Edit", "Preview"];
  setActiveViewTab: (tab: "Edit" | "Preview") => void;
}

export const CustomDataOpsHeader = ({ selectedItem, viewTabs, activeViewTab, setActiveViewTab }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customDataOpsData } = useSelector((state: RootState) => state.customDataOpsStateReducer);
  const [isPublishLoading, setIsPublishLoading] = useState(false);

  const onPublishHandler = () => {
    setIsPublishLoading(true);
    const body = { ...selectedItem, published: !selectedItem.published };
    fetchPatchJson(`su/customtools/${selectedItem.id}`, token, body)
      .then((res: TODO) => res.json())
      .then((data: TODO) => {
        setIsPublishLoading(false);
        if (data.error || data.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error || data.message } });
        } else {
          if (customDataOpsData) {
            dispatch(updateCustomDataOpsData(customDataOpsData.map(item => item.id === selectedItem.id ? { ...item, ...data } : item)));
          }
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: `The item has been ${data.published ? 'published' : 'unpublished'} successfully` } });
        }
      })
  }

  return (
    <div className="d-flex justify-content-between border-bottom p-4">
      <div className="d-flex align-items-center gap-lg">
        <button type='button' onClick={() => dispatch(updateCustomDataOpsSelectedItem(""))} className="btn btn-shadow px-1">
          <Icon type="chevron-left" />
        </button>
        <h5 className='mb-0 stronger'>{selectedItem.name}</h5>
        <span className={`badge d-flex align-items-center h-24 px-2 ${selectedItem.published ? 'badge-success-light' : 'badge-light'}`}>{selectedItem.published ? 'Published' : 'Draft'}</span>
      </div>
      <div className="list-group list-group-horizontal">
        {
          viewTabs.map(tab =>
            <button
              key={tab}
              type="button"
              onClick={() => setActiveViewTab(tab)}
              className={`list-group-item list-group-item-action py-1 ${activeViewTab === tab ? 'active' : ''}`}>
              {tab}
            </button>
          )
        }
      </div>
      <div className="d-flex align-items-center justify-content-end gap-md">
        <button
          type='button'
          className="btn btn-shadow px-2"
          onClick={() => dispatch(updateCustomDataOpsShowCreateEditModal({ show: true, item: selectedItem }))}>
          <i className="far fa-lg fa-pen mr-2" />
          Edit
        </button>
        <button
          type='button'
          className={"btn btn-shadow px-2"}
          onClick={onPublishHandler}>
          {
            isPublishLoading ?
              <span className="spinner-border spinner-border-sm pe-none" role="status" aria-hidden="true" />
              :
              <i
                className="far fa-lg fa-arrow-up px-1 mr-1 pe-none"
                style={{ rotate: selectedItem.published ? "225deg" : "45deg" }}
              />
          }
          {selectedItem.published ? "Unpublish" : "Publish"}
        </button>
      </div>
    </div>
  )
}