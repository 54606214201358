import { useSelector } from 'react-redux';

import { LogicTabContent } from './LogicTabContent/LogicTabContent';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { Icon } from '../../../../shared/Icon/Icon';

interface Props {
  handleEditTool: () => void
}

export const WorkflowDesignTabs = ({ handleEditTool }: Props) => {
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);

  return (
    workflowData.selectedTool ?
      <LogicTabContent handleEditTool={handleEditTool} />
      :
      <div className="h-100 w-100 d-flex">
        <div className="card-body d-flex flex-column h-100 bg-white align-items-center justify-content-center">
          <Icon type="dashboard-edit" className='pe-none fill-primary select-placeholder' />
          <h1 className="droppable-text strong mt-2 h4">Select tool to edit</h1>
        </div>
      </div>)
}