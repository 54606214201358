import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../store/reducers/rootReducer";
import { parseJwt } from "../shared/helpers/decodeJWT/decodeJWT";
import { useEffect, useState } from "react";
import { fetchGetJson } from "../../services/services";
import { DropdownButton } from "../shared/DropdownButton/DropdownButton";

type BuyersData = {
  id: string;
  customerId: string;
  name: string;
}

type Props = {
  setIsOverlayLoading: (overlayLoading: boolean) => void;
}

export const AudienceAdmin = ({ setIsOverlayLoading }: Props) => {
  const { name, customerId } = useSelector((state: RootState) => state.audienceAdminReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const [buyersData, setBuyersData] = useState<BuyersData[]>();
  const dispatch = useDispatch();

  const audienceAdmin = parseJwt(token);
  const isAudienceAdmin = audienceAdmin[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/audienceAdmin`] as unknown as boolean;

  useEffect(() => {
    fetchGetJson("au/a/integrationpartners/buyers", token).then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
      } else {
        setBuyersData(res);
      }
    })
  }, [dispatch, token]);

  const onItemSelect = (e: TODO) => {
    if (customerId === e.item.customerId && name === e.item.name) {
      dispatch({ type: "SET_SELECTED_BUYER", payload: { name: "", customerId: null } })
      setIsOverlayLoading(true);
    } else {
      setIsOverlayLoading(true);
      dispatch({ type: "SET_SELECTED_BUYER", payload: { name: e.item.name, customerId: e.item.customerId } })
    }
  }

  return isAudienceAdmin ?
    <DropdownButton
      items={buyersData}
      textField="name"
      className="btn-shadow icon-r overview-input"
      text={name ? name : "Select Buyer"}
      onItemClick={(e) => onItemSelect(e)}
    />
    :
    null
}