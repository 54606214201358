export default (analysisFunctionalities, tabs, user) => {
  let enabledTabs, activeTabs = []
  if (analysisFunctionalities) {
    enabledTabs = Object.keys(analysisFunctionalities.analysisTypes).filter(type => analysisFunctionalities.analysisTypes[type] === true)
    if (enabledTabs) {
      enabledTabs.forEach(type => {
        tabs.forEach(tab => {
          if (type.toLowerCase().includes(tab.id.toLowerCase())) {
            activeTabs.push(tab)
          }
        })
      })
    }
  }
  activeTabs.push({ id: "Scripting", title: "Scripting", icon: "survey-xml" }, { id: "Reports", title: "Reports", icon: "help-report" })
  return activeTabs
}