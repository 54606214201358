import React from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../../../../../../shared/Icon/Icon';
import type { Type } from '../../../../../../../interfaces/iconInterfaces/iconInterfaces';

interface Props {
  provided?: TODO
  questionIcon: Type
  checkboxChecked: boolean
  itemQuestions: { title: string, content: string, id: string }[]
}

export const GroupQuestionDropdownButton: React.FC<Props> = ({ provided, questionIcon, checkboxChecked, itemQuestions }) => {
  return (
    <React.Fragment>
      {/* DROPDOWN TOGGLE */}
      <button
        type="button"
        // onClick={(e) => e.stopPropagation()}
        className="btn btn-transparent dropdown-toggle group-dropdown-toggle shadow-none mr-1"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">
        <i className={`fas fa-chevron-down ${checkboxChecked ? 'text-white' : ''}`} />
      </button>

      {/* DROPDOWN MENU */}
      <div
        data-rbd-drag-handle-context-id={provided.dragHandleProps?.["data-rbd-drag-handle-context-id"]}
        data-rbd-drag-handle-draggable-id="disable-dnd"
        aria-labelledby="dropdownMenuButton"
        className="dropdown-menu cursor-default border p-0 mt-5"
        onClick={(e) => e.stopPropagation()}>
        <div className='p-2 d-flex flex-column gap-md'>
          <p className='mx-1 mb-0 text-muted'>Grouped questions:</p>
          {
            itemQuestions.map((item) => (
              <div key={item.id} className={"p-2 d-flex align-items-center gap-md rounded border"}>
                <Icon type={questionIcon} className="question-icon" />
                <div className="d-flex flex-column">
                  <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
                    <p title={item.title} className="question-title text-primary">{item.title}</p>
                  </Tooltip>
                  <Tooltip openDelay={100} position='auto' anchorElement={'target'}>
                    <p title={item.content} className="question-description text-truncate">{item.content}</p>
                  </Tooltip>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </React.Fragment>
  )
}