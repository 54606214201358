import { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import {
  DropDownList,
  type DropDownListFilterChangeEvent,
  type DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';
import {
  filterBy,
  type CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import AdvancedWeightingTable from './AdvancedWeightingTable';
import { fetchGetJson, fetchPostJson } from '../../../../../../../../services/services';
import { useDispatch } from 'react-redux';

interface StepTwoProps {
  token: string;
  projectId: string;
  datasetId: string;
  mainTemplateData: TemplateData | null;
  setMainTemplateData: (value: TemplateData) => void;
  validateTemplateData: (template: TemplateData) => Promise<{ validate: boolean }>;
  isValidating: boolean;
  setShouldValidate: (value: boolean) => void;
}

const transformVariableId = (variableId: string) => {
  return variableId.split('.')[0];
};

const getSubgroupFromExpression = (expression: string) => {
  const subGroup = expression.split('=')[0].replace(/\\/g, '');
  return subGroup;
};

const findSubgroupFromVariables = (variables: Variable[], expression: string) => {
  const subGroup = getSubgroupFromExpression(expression);
  return variables.find((variable) => transformVariableId(variable.qno) === subGroup);
};

const StepTwo = ({
  token,
  projectId,
  datasetId,
  mainTemplateData,
  setMainTemplateData,
  validateTemplateData,
  isValidating,
  setShouldValidate,
}: StepTwoProps) => {
  const dispatch = useDispatch();
  const [variables, setVariables] = useState<Variable[]>([]);
  const [filteredSubgroupData, setFilteredSubgroupData] = useState<Variable[]>([]);
  const [filteredTargetData, setFilteredTargetData] = useState<Variable[]>([]);

  const [subGroup, setSubGroup] = useState<Variable[]>([]);
  const [targets, setTargets] = useState<Variable[]>([]);
  const [templateData, setTemplateData] = useState<TemplateData | null>(null);

  useEffect(() => {
    const fetchVariables = async () => {
      try {
        const response: Variable[] = await fetchGetJson(
          `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced/candidates`,
          token,
        );
        setVariables(response);
      } catch (error) {
        console.error('Error fetching variables:', error);
      }
    };
    fetchVariables();
  }, [projectId, datasetId, token]);

  useEffect(() => {
    if (variables.length > 0 && mainTemplateData) {
      setTemplateData(mainTemplateData);

      const newSubGroup: Variable[] = [];
      const newTargets: Variable[] = [];

      if (mainTemplateData.subGroup && Array.isArray(mainTemplateData.subGroup.categories)) {
        const subGroupExpression = mainTemplateData.subGroup.categories[0].expression;
        const subGroupVariable = findSubgroupFromVariables(variables, subGroupExpression);

        if (subGroupVariable) {
          newSubGroup.push(subGroupVariable);
        }
      }

      if (mainTemplateData.targets && Array.isArray(mainTemplateData.targets)) {
        for (const question of mainTemplateData.targets) {
          const variable = variables.find((v) => transformVariableId(v.qno) === question.id);
          if (variable) {
            newTargets.push(variable);
          }
        }
      }

      setSubGroup(newSubGroup);
      setTargets(newTargets);
    } else if (!mainTemplateData) {
      setTemplateData(null);
      setSubGroup([]);
      setTargets([]);
    }
  }, [mainTemplateData, variables]);

  useEffect(() => {
    const getAvailableVariables = (allVariables: Variable[], selectedVariables: Variable[]) => {
      const selectedIds = selectedVariables.map(v => v.id);
      return allVariables.filter(v => !selectedIds.includes(v.id));
    };

    setFilteredSubgroupData(getAvailableVariables(variables, subGroup));
    setFilteredTargetData(getAvailableVariables(variables, targets));
  }, [variables, subGroup, targets]);

  const filterData = (data: Variable[], filter: CompositeFilterDescriptor): Variable[] => {
    return filterBy(data, filter);
  };

  const handleSubgroupFilterChange = (event: DropDownListFilterChangeEvent) => {
    const filter = event.filter;
    const newData =
      filter?.value && filter.value.length > 0 ? filterData(variables, { logic: 'and', filters: [filter] }) : variables.slice();
    setFilteredSubgroupData(newData);
  };

  const handleTargetFilterChange = (event: DropDownListFilterChangeEvent) => {
    const filter = event.filter;
    const newData =
      filter?.value && filter.value.length > 0 ? filterData(variables, { logic: 'and', filters: [filter] }) : variables.slice();
    setFilteredTargetData(newData);
  };

  const handleSubgroupChange = (event: DropDownListChangeEvent) => {
    const variable = event.target.value as Variable;
    if (variable) {
      setSubGroup(() => {
        const newSubGroup = [variable];
        createWeightTemplate(newSubGroup, targets);
        return newSubGroup;
      });
    }
  };

  const handleTargetChange = (event: DropDownListChangeEvent) => {
    const variable = event.target.value as Variable;
    if (variable) {
      setTargets((prevTargets) => {
        const alreadyExists = prevTargets.some((target) => target.id === variable.id);
        if (alreadyExists) {
          dispatch({
            type: 'SHOW_WARNING_NOTIFICATION',
            payload: { msg: 'Target already added' },
          });
          return prevTargets;
        }

        const newTargets = [...prevTargets, variable];
        createWeightTemplate(subGroup, newTargets);
        return newTargets;
      });
    }
  };

  const createWeightTemplate = async (subgroupVariable: Variable[], targetsVariable: Variable[]) => {
    setShouldValidate(true);
    try {
      const subGroupQuestions = subgroupVariable.map((variable) => ({
        id: transformVariableId(variable.qno),
        subqIndex: variable.subqIndex,
        rows: variable.rows.map((row) => ({ code: row.code })),
        isDisabled: false,
      }));
      const targetsQuestions = targetsVariable.map((variable) => ({
        id: transformVariableId(variable.qno),
        subqIndex: variable.subqIndex,
        rows: variable.rows.map((row) => ({ code: row.code })),
        isDisabled: false,
      }));
      const payload = {
        subGroup:
          subgroupVariable.length > 0
            ? {
              isInterlocked: false,
              questions: subGroupQuestions,
            }
            : null,
        targets: {
          questions: targetsQuestions,
        },
      };

      if (targetsVariable.length > 0 || subgroupVariable.length > 0) {
        const response: TemplateData = await fetchPostJson(
          `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced/template-definition`,
          token,
          payload,
        );
        setTemplateData({ ...response });
      } else {
        setTemplateData(null);
      }
    } catch (error) {
      console.error('Error creating weight template:', error);
    }
  };

  const removeSubGroup = () => {
    setSubGroup([]);
    createWeightTemplate([], targets);
  };

  const removeTarget = (targetId: string) => {
    setTargets((prevTargets) => {
      const updatedTargets = prevTargets.filter((target) => transformVariableId(target.qno) !== targetId);
      createWeightTemplate(subGroup, updatedTargets);
      return updatedTargets;
    });
  };

  return (
    <div>
      <div className="d-flex align-items-center bg-light">
        <div className="d-flex align-items-center">
          {!!subGroup.length && <span className="font-weight-bold mr-4">{transformVariableId(subGroup[0]?.qno)}</span>}
          <DropDownList
            data={filteredSubgroupData}
            textField="qno"
            dataItemKey="id"
            filterable={true}
            onFilterChange={handleSubgroupFilterChange}
            onChange={handleSubgroupChange}
            style={{ width: '250px' }}
            size="small"
            value={null}
            valueRender={() => subGroup.length ? "Change subgroup" : "+ Add subgroup"}
            className='align-items-center'
            fillMode='solid'
          />

          {!!subGroup.length && <Button
            size="small"
            className="ml-4 text-danger"
            type="button"
            onClick={removeSubGroup}
          >
            - Remove SubGroup
          </Button>}

        </div>
      </div>

      {templateData && (
        <AdvancedWeightingTable
          isEditable={true}
          data={templateData}
          setMainTemplateData={setMainTemplateData}
          validateTemplateData={validateTemplateData}
          isValidating={isValidating}
          onRemoveTarget={removeTarget}
        />
      )}

      <div className="d-flex flex-column mt-4">
        <div className="bg-custom-questions-grey">
          <div className="d-flex align-items-center">
            <DropDownList
              data={filteredTargetData}
              textField="qno"
              dataItemKey="id"
              filterable={true}
              onFilterChange={handleTargetFilterChange}
              onChange={handleTargetChange}
              style={{ width: '250px' }}
              size="small"
              value={null}
              valueRender={() => "+ Add target"}
              className='align-items-center'
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export default StepTwo;
