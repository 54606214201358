import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { CustomDataOpsData, CustomDataOpsState } from '../../../interfaces/customDataOpsInterface/customDataOpsInterface';

const initialState = {
  customDataOpsData: null,
  selectedItemId: '',
  showCreateEditModal: { show: false, item: null },
  showDeleteModal: { show: false, item: '' },
  searchValue: '',
  tagsFilter: [],
  customersFilter: [],
  editorTheme: 'vs-dark',
} as CustomDataOpsState;

const customDataOpsSlice = createSlice({
  name: 'customDataOps',
  initialState,
  reducers: {
    updateCustomDataOpsData(state, action: PayloadAction<CustomDataOpsData[] | null>) {
      state.customDataOpsData = action.payload;
    },
    updateCustomDataOpsSelectedItem(state, action: PayloadAction<string>) {
      state.selectedItemId = action.payload;
    },
    updateCustomDataOpsShowCreateEditModal(state, action: PayloadAction<{ show: boolean, item: CustomDataOpsData | null }>) {
      state.showCreateEditModal = action.payload;
    },
    updateCustomDataOpsShowDeleteModal(state, action: PayloadAction<{ show: boolean, item: string }>) {
      state.showDeleteModal = action.payload;
    },
    updateCustomDataOpsSearchValue(state, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },
    updateCustomDataOpsTagsFilter(state, action: PayloadAction<string[]>) {
      state.tagsFilter = action.payload;
    },
    updateCustomDataOpsCustomersFilter(state, action: PayloadAction<{ name: string, id: string }[]>) {
      state.customersFilter = action.payload;
    },
    updateCustomDataOpsEditorTheme(state, action: PayloadAction<'vs-dark' | 'vs-light'>) {
      state.editorTheme = action.payload;
    }
  },
})

export const {
  updateCustomDataOpsData,
  updateCustomDataOpsSelectedItem,
  updateCustomDataOpsShowCreateEditModal,
  updateCustomDataOpsShowDeleteModal,
  updateCustomDataOpsSearchValue,
  updateCustomDataOpsTagsFilter,
  updateCustomDataOpsCustomersFilter,
  updateCustomDataOpsEditorTheme,
} = customDataOpsSlice.actions;

export default customDataOpsSlice.reducer;