import { Input, Switch } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { mapRoundDirection } from './utils/utils';

interface StepFourProps {
  roundingValues: Rounding;
  setRoundingValues: (value: Rounding) => void;
}

enum RoundingDirection {
  UP = 0,
  DOWN = 1
}

const StepFour = ({ roundingValues, setRoundingValues }: StepFourProps) => {
  return (
    <div className="k-form">
      <h4 className="k-form-title">Rounding</h4>
      <div className="k-form-field d-flex align-items-center mb-4">
        <div className='p-4 mr-4 w-50 align-items-center'>
          <label htmlFor="define-rounding-values" className="k-label">Define rounding requirements</label>
          <p className='font-italic small text-gray'>Toggle on this feature and enter the number of decimal places required below.</p>
        </div>
        <div>
          <Switch
            id="define-rounding-values"
            checked={roundingValues?.enabled}
            onChange={(e) => setRoundingValues({ ...roundingValues, enabled: e.target.value })}
            size="small"
          />
        </div>
      </div>
      <div className="k-form-field d-flex align-items-center mb-4">
        <div className='p-4 mr-4 w-50'>
          <label htmlFor="rounding-decimal-places" className="k-label">Define decimal place for rounding</label>
          <p className="font-italic small text-gray">Enter the number of decimal places where rounding should end. Maximum number of places is 8</p>
        </div>
        <div>
          <Input
            type='number'
            max={8}
            id='rounding-decimal-places'
            value={roundingValues?.decimals}
            onChange={(e) => setRoundingValues({ ...roundingValues, decimals: Number(e.target.value) })}
            disabled={!roundingValues?.enabled}
          />
        </div>
      </div>

      <div className="k-form-field d-flex align-items-center">
        <div className='p-4 mr-4 w-50'>
          <label htmlFor="rounding-direction" className="k-label">Define Rounding direction</label>
          <p className="font-italic small text-gray">Decide if rounding is up or down. Default is up from 5 and above</p>
        </div>
        <div className="d-flex flex-nowrap h-fit">
          <DropDownList
            data={["UP", "DOWN"]}
            defaultValue="UP"
            id="rounding-direction"
            value={mapRoundDirection(roundingValues?.roundingDirection)}
            onChange={(e) => setRoundingValues({ ...roundingValues, roundingDirection: RoundingDirection[e.target.value as keyof typeof RoundingDirection] })}
            disabled={!roundingValues?.enabled}
          />
        </div>
      </div>
    </div>
  );
}

export default StepFour;