const initialState = {
  customerId: null,
  name: null
};

function audienceAdminReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_SELECTED_BUYER":
      return {
        ...state,
        name: action.payload.name,
        customerId: action.payload.customerId
      };
    default:
      return state;
  }
}

export default audienceAdminReducer;