import { type FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "../../../shared/Icon/Icon"
import type { searchData } from "../ProjectsTypes/projectTypes";
import type { RootState } from "../../../../store/reducers/rootReducer";
import { rsAppUrl, fetchPutResOrJson as updateToggleView } from '../../../../services/services';
import { useAuth0 } from "@auth0/auth0-react";

type Props = {
  onSearchSubmit: ({ searchTerm, sortOrder, sort, deleteSearchTerm, deleteSortOrder }: searchData) => void;
  searchParams: URLSearchParams
}

export const ProjectsHeader = ({ onSearchSubmit, searchParams }: Props) => {
  const userSettings = useSelector((state: RootState) => state.userSettingsReducer);
  const userExtendedData = useSelector((state: RootState) => state.userExtendedDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const { user } = useAuth0();

  const searchQuery = searchParams.get("searchTerm");

  const searchTerm = searchQuery ? searchQuery : "";
  const [searchValue, setSearchValue] = useState(searchTerm);

  const onCreate = () => {
    dispatch({ type: "UPDATE_SHOW_ADD_PROJECT_MODAL", payload: true })
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (searchValue === "") return;

    onSearchSubmit({ searchTerm: searchValue });
  }

  const postToggle = (toggle: boolean) => {
    const { compactView, ...oldUserSettings } = JSON.parse(JSON.stringify(userSettings));
    const body = {
      settings: { ...oldUserSettings, toggleView: toggle },
    };
    dispatch({
      type: "UPDATE_USER_SETTINGS",
      payload: { ...oldUserSettings, toggleView: toggle },
    });
    updateToggleView(
      `users/${user?.[`${rsAppUrl}/userid`]}/settings`,
      token,
      body
    ).then((res: TODO) => {
      if (res.status !== 200) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Something went wrong. Please try again." } })
      }
    });
  };


  return (
    <section className="d-flex justify-content-between align-items-end p-6">
      <div className="d-flex flex-column gap-lg">
        <h1 className="stronger h4 m-0">
          Projects
        </h1>

      {!userExtendedData.isSubAccount &&
        <div className="d-flex gap-md">
            <button type="button" className="btn btn-primary" onClick={onCreate}>
            <Icon type="plus" />
            Create new
          </button>
        </div>
      }

      </div>
      <div className="d-flex gap-md">
        <form className="d-flex position-relative" onSubmit={onSubmit}>
          <input
            name='Search'
            type="text"
            value={searchValue}
            className="form-control search-input-projects"
            placeholder="Search"
            aria-label="Search"
            autoComplete="off"
            minLength={3}
            onChange={(e: TODO) => setSearchValue(e.target.value)}
            style={{ width: "15rem" }}
          />

          <button type="submit" name="submitSearch" className="btn btn-search-submit" title="Search projects">
            <Icon type="search" />
          </button>

          {(searchValue?.length >= 3 || searchTerm) && (
            <button type="button"
              className="btn position-absolute search-clear-button-projects"
              style={{ right: "2.5rem", zIndex: 11 }}
              onClick={() => {
                setSearchValue("");
                onSearchSubmit({ deleteSearchTerm: true })
              }}
              title="Clear search">
              <Icon type="close" />
            </button>
          )}
        </form>

        <div className="btn-group" role="group" aria-label="Projects view switch">
          <button
            type="button"
            onClick={() => postToggle(true)}
            className={`btn ${userSettings.toggleView ? "btn-secondary" : "btn-outline-secondary"} text-dark shadow-none`}>
            <i className="fas fa-th" />
          </button>
          <button
            type="button"
            onClick={() => postToggle(false)}
            className={`btn ${userSettings.toggleView === false ? "btn-secondary" : "btn-outline-secondary"} text-dark shadow-none`}>
            <i className="fas fa-bars" />
          </button>
        </div>
      </div>

    </section>
  )
}