import { useEffect, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import { AudienceReports } from './ReportsAudience/AudienceReports';
import { AudienceDetails } from './Details/AudienceDetails'
import { AudienceReconcile } from './Reconcile/AudienceReconcile'
import { AudienceTargeting } from './Targeting/AudienceTargeting'
import type { Audience, AudienceOverviewTabsType } from '../../interfaces/auddineceAccessInterfaces/auddineceAccessInterfaces';
import { AudienceOverviewTabs } from '../../interfaces/auddineceAccessInterfaces/auddineceAccessInterfaces'
import { fetchGetJson } from '../../services/services'
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage'
import { editAudienceStatus, setAudience } from '../../store/reducers/audienceAccessReducer/audienceAccessReducer';
import type { RootState } from '../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../shared/FullHeightSpinner/FullHeightSpinner';
import { Header } from './shared/Header';
import { CustomAudienceModal } from './CustomAudienceModal/CustomAudienceModal';
import { UpdateStatusModal } from './shared/UpdateStatusModal'
import { Icon } from '../shared/Icon/Icon';

export const AudienceOverview = () => {
  const [selectedTab, setSelectedTab] = useState<AudienceOverviewTabsType>("Details")
  const [didMount, setDidMount] = useState(false)
  const [errorModal, setErrorModal] = useState({ show: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [showCustomAudienceModal, setShowCustomAudienceModal] = useState<{ show: boolean, editMode: boolean }>({ show: false, editMode: false })
  const [showUpdateStatustModal, setShowUpdateStatusModal] = useState(false)
  const data = useSelector((state: RootState) => state.audienceDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [selectedStatus, setSelectedStatus] = useState(data.audienceStatus)
  const { user } = useAuth0()
  const userEmail = user?.email ? user.email : ''
  const params: { id: string, name: string } = useParams()
  const dispatch = useDispatch()
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const customerIDqueryParam = searchParams.get('cid');

  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      setIsLoading(true)
      if (params.id) {
        fetchGetJson(`au/a/projects/${params.name}/audiences/${params.id}`, token, undefined, customerIDqueryParam)
          .then((res: Audience) => {
            if (!res.message) {
              setIsLoading(false)
              dispatch(setAudience({ ...res }))
            } else {
              setErrorModal({ show: true, message: res.message })
            }
          }).catch((err: TODO) => {
            setErrorModal({ show: true, message: err?.message })
          })
      } else {
        setDidMount(true)
        setIsLoading(false)
        dispatch(editAudienceStatus("New"))
      }
    }
  }, [params.id, params.name, didMount, dispatch, token, customerIDqueryParam])

  const handleTabSelected = (tab: AudienceOverviewTabsType) => data.audienceStatus !== 'New' && setSelectedTab(tab)

  const onHide = () => {
    setShowUpdateStatusModal(false);
  };

  const selectedTabComponents = () => {
    switch (selectedTab) {
      case "Details":
        return <AudienceDetails setDidMount={setDidMount} customerIDqueryParam={customerIDqueryParam} />;
      case "Reconcile":
        return <AudienceReconcile customerIDqueryParam={customerIDqueryParam} />
      case "Reports":
        return <AudienceReports />
      case "Targeting":
        return <AudienceTargeting setShowCustomAudienceModal={setShowCustomAudienceModal} />
    }
  }

  return (
    <section className='main audiences-container overview overflow-hidden p-0' >
      {isLoading ?
        <FullHeightSpinner /> : (
          <>
            {showCustomAudienceModal.show &&
              <CustomAudienceModal
                customAudienceModalValues={data}
                handleModalVisibility={setShowCustomAudienceModal}
                interviewLength={`${data.lengthOfInterview}`}
                onSubmitHandler={() => { }}
                selectedMarkets={[{ IncidenceRate: `${data.incidenceRate}`, MarketName: data.country, TargetNumberOfCompletes: `${data.quantity}` }]}
                token={token}
                isoLang={data.language?.isoCode || ''}
                userEmail={userEmail}
                editMode={showCustomAudienceModal.editMode}
                customerIDqueryParam={customerIDqueryParam}
              />}
            {!errorModal.show &&
              <>
                <div className="app-header">
                  <Link
                    to={"/audiences"}
                    className='d-flex back-to-project justify-content-center align-items-center w-56 border-right cursor-pointer'>
                    <div className='walr'>
                      <Icon className='walr pe-none' type="walr" />
                    </div>
                    <div className='exit'>
                      <Icon className='exit pe-none' type="exit" />
                    </div>
                    <span className='tooltip'>
                      Back to Audiences
                    </span>
                  </Link>

                  <ul className="nav nav-pills app-tabs flex-grow-1 justify-content-center">
                    {
                      AudienceOverviewTabs.map((tab, index) => (
                        <li
                          key={index}
                          className={`nav-link ${selectedTab === tab && 'active'} ${data.audienceStatus === "New" && "disabled"}`}
                          onClick={() => handleTabSelected(tab)}
                        >
                          {tab}
                        </li>
                      ))
                    }
                  </ul>

                  <div className={`d-flex w-100 ${data.audienceStatus === "New" && "hide-status-badge"}`}>
                    <Header selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} setShowUpdateStatusModal={setShowUpdateStatusModal} />
                  </div>
                </div>

                <div className='overview-container d-flex flex-column flex-grow-1 p-4'>
                  {selectedTabComponents()}
                </div>
              </>
            }
          </>
        )}
      {errorModal.show &&
        <ErrorMessage
          type="modal"
          errorMessage={errorModal.message}
          onHide={() => setErrorModal({ show: false, message: '' })}
        />
      }
      {showUpdateStatustModal && <UpdateStatusModal selectedStatus={selectedStatus} customerIDqueryParam={customerIDqueryParam} onHide={onHide} />}
    </section>
  )
}