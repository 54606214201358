import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "../shared/Icon/Icon";
import { DropdownButton } from "../shared/DropdownButton/DropdownButton";
import { createNewAudience } from '../../store/reducers/audienceAccessReducer/audienceAccessReducer';
import type { AudienceTabsType, AudiencesData, Market, Markets } from "../../interfaces/auddineceAccessInterfaces/audienceTypes";
import { AudienceAdmin } from "./AudienceAdmin";
import type { RootState } from "../../store/reducers/rootReducer";
import { fetchGetJson } from '../../services/services'

type Props = {
  setSearchTerm: (search: string) => void;
  searchTerm: string | null;
  setSelectedMarket: (market: {
    name: string;
    isoCode: string;
  }) => void;
  selectedMarket: { name: string, isoCode: string }
  selectedTab: AudienceTabsType
  fetchAudiencesData: (searchTermValue: string, selectedMarketValue: string, status: string, continuationToken?: string) => void;
  setAudiencesData: (response: AudiencesData) => void;
  setIsLoading: (loading: boolean) => void;
  setIsOverlayLoading: (overlayLoading: boolean) => void;
}

export const AudiencesHeader = ({ setSearchTerm, searchTerm, setSelectedMarket, selectedMarket, selectedTab, fetchAudiencesData, setAudiencesData, setIsLoading, setIsOverlayLoading }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);

  const [markets, setMarkets] = useState<Market[]>([])
  const dispatch = useDispatch();

  useEffect(() => {
    fetchGetJson("au/a/markets", token).then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
      } else {
        setMarkets(res.map((market: Markets) => ({ "text": market.name, isoCode: market.isoCode })))
      }
    })
    fetchGetJson("au/a/audiences", token, undefined, customerId).then((res: TODO) => {
      if (res.error || res.message) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } })
      } else {
        setAudiencesData(res)
      }
      setIsOverlayLoading(false);
      setIsLoading(false)
    })
  }, [dispatch, token, customerId])

  return (
    <>
      <div className='d-flex w-100 px-6 pt-5 pb-2 gap-lg'>
        <h1 className='stronger h4'>Audiences</h1>
        <AudienceAdmin setIsOverlayLoading={setIsOverlayLoading} />
      </div>

      <div className='d-flex justify-content-between w-100 px-6 py-0'>
        <Link to={"/audiences/create"}>
          <button type="button" className='btn btn-primary btn-icon icon-l' onClick={() => dispatch(createNewAudience())}>
            <Icon type="add" className="submenu fill-white" />
            Create new
          </button>
        </Link>

        <div className='d-flex justify-content-between'>
          <div className="input-group mr-3 h-32">
            <input
              name='Search'
              type="text"
              value={searchTerm === null ? '' : searchTerm}
              className="form-control search-input bg-white overview-input"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className='d-flex position-relative'>
            <DropdownButton
              items={markets}
              className="btn-shadow icon-r overview-input"
              text={selectedMarket.name ? selectedMarket.name : "All markets"}
              onItemClick={(e) => {
                setSelectedMarket({ name: e.item.text, isoCode: e.item.isoCode });
                fetchAudiencesData(searchTerm ? searchTerm : '', e.item.isoCode, selectedTab)
              }}
            />

            <button
              type="button"
              disabled={(selectedMarket.name === "All markets" || selectedMarket.name === "") ? true : false}
              className='btn p-0 h-24 shadow-none btn-reset bg-white'
              onClick={() => {
                setSelectedMarket({ name: "All markets", isoCode: "" })
                fetchAudiencesData("All markets", "", selectedTab)
              }}>
              <Icon type="close" />
            </button>
          </div>
        </div>
      </div>
    </>
  )
}