import { createSlice, type PayloadAction } from '@reduxjs/toolkit'


const initialState: TODO = {
  report: {},
  tableData: "",
  isLoadingData: false,
  isEditReport: false,
  selectedReportFolder: undefined
}

const AnalysisReportsDataSlice = createSlice({
  name: 'analysisReports',
  initialState,
  reducers: {
    setReport(_state, action: PayloadAction<TODO>) {
      return { ...initialState, report: action.payload }
    },
    updateReport(state, action: PayloadAction<TODO>) {
      return { ...state, report: action.payload }
    },
    updateTableData(state, action: PayloadAction<string>) {
      return { ...state, tableData: action.payload }
    },
    updateLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoadingData: action.payload }
    },
    updateEditReport(state, action: PayloadAction<boolean>) {
      return { ...state, isEditReport: action.payload }
    },
    updateCurrentReportFolder(state, action: PayloadAction<string>) {
      return { ...state, selectedReportFolder: action.payload }
    }
  }
})

export const {
  setReport,
  updateReport,
  updateTableData,
  updateLoading,
  updateEditReport,
  updateCurrentReportFolder,
} = AnalysisReportsDataSlice.actions
export default AnalysisReportsDataSlice.reducer