import { MatchToolTabContent } from './MatchToolTabContent/MatchToolTabContent';
import { AppendToolTabContent } from './AppendToolTabContent/AppendToolTabContent';
import { CodingToolTabContent } from './CodingToolTabContent/CodingToolTabContent';
import { CustomToolTabContent } from './CustomToolTabContent/CustomToolTabContent';
import { CleanAndDefineToolTabContent } from './CleanAndDefineToolTabContent/CleanAndDefineToolTabContent';

interface Props {
  toolType: string
  isCustomTool?: boolean
  handleEditTool: () => void
}

export const TabContent = ({ toolType, isCustomTool, handleEditTool }: Props) => {
  switch (toolType) {
    case "CleanAndDefine":
      if (isCustomTool) {
        return <CustomToolTabContent handleEditTool={handleEditTool} />
      }
      return <CleanAndDefineToolTabContent handleEditTool={handleEditTool} />
    case "Append":
      return <AppendToolTabContent handleEditTool={handleEditTool} />
    case "Match":
      return <MatchToolTabContent handleEditTool={handleEditTool} />
    case "Coding":
      return <CodingToolTabContent handleEditTool={handleEditTool} />
    default:
      return null
  }
}