const initialState = {
        isTrail: null,
        customerName: null,
        trailEnds: null,
        createdAt: null,
        isSubAccount: null,
        canCreateSubAccounts: null,
        headerBackgroundColor: null,
        headerLogoUrl: null,
        isApiUser: null,
        isPwdLessSubAccountUser: null,
        originalCustomerName: null,
        subscriptionPlan: null,
        subscriptionStatus: null,
        userId: null,
  };
  
  function userExtendedDataReducer(state = initialState, action) {
    switch (action.type) {
      case "UPDATE_USER_EXTENDED_DATA":
        return action.payload;

      default:
        return state;
    }
  }
  
  export default userExtendedDataReducer;
  