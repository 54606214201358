import React, { useState, useEffect } from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';
import { FullHeightSpinner } from '../../../../../../../shared/FullHeightSpinner/FullHeightSpinner';


interface AdvancedWeightingTableProps {
  data: TemplateData;
  setMainTemplateData?: (value: TemplateData) => void;
  validateTemplateData?: (template: TemplateData) => Promise<{ validate: boolean }>;
  isEditable: boolean;
  isValidating?: boolean;
  onRemoveTarget?: (targetId: string) => void;
}

const AdvancedWeightingTable: React.FC<AdvancedWeightingTableProps> = ({
  data,
  isEditable,
  setMainTemplateData,
  validateTemplateData,
  isValidating,
  onRemoveTarget,
}) => {
  const [tableData, setTableData] = useState<TemplateData>(data);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (!tableData) {
    return null;
  }

  const onValidate = async () => {
    const response = await validateTemplateData?.(tableData);

    if (response?.validate) {
      setMainTemplateData?.(tableData);
    }
  };

  const preventKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      return;
    }
  };

  function mergeData(newData: TemplateData, existingData: TemplateData): TemplateData {
    const mergedData = JSON.parse(JSON.stringify(newData));

    if (!existingData || !existingData.subGroup) {
      return mergedData;
    }

    for (let catIndex = 0; catIndex < mergedData?.subGroup?.categories?.length; catIndex++) {
      const newCategory = mergedData?.subGroup?.categories[catIndex];
      const existingCategory = existingData?.subGroup?.categories.find(
        (cat) => cat.text === newCategory.text
      );

      if (existingCategory) {
        if (existingCategory.targetBasePct !== undefined) {
          newCategory.targetBasePct = existingCategory.targetBasePct;
        }

        for (let refIndex = 0; refIndex < newCategory.targetReferences.length; refIndex++) {
          const newTargetReference = newCategory.targetReferences[refIndex];
          const existingTargetReference = existingCategory.targetReferences.find(
            (ref) => ref.id === newTargetReference.id
          );

          if (existingTargetReference) {
            for (
              let propIndex = 0;
              propIndex < newTargetReference.proportions.length;
              propIndex++
            ) {
              const existingProportion = existingTargetReference.proportions[propIndex];
              if (existingProportion) {
                newTargetReference.proportions[propIndex].pct = existingProportion.pct;
              }
            }
          }
        }
      }
    }

    if (existingData.targetPopulation !== undefined) {
      mergedData.targetPopulation = existingData.targetPopulation;
    }

    return mergedData;
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <not needed>
  useEffect(() => {
    const performMerge = () => {
      setIsLoading(true);
      setTableData((prevData) => {
        const merged = mergeData(data, prevData);
        return merged;
      });
      setIsLoading(false);
    };

    performMerge();
  }, [data]);

  const hasSubGroups = tableData.subGroup && tableData.subGroup.categories.length > 0;
  const categories = hasSubGroups ? tableData?.subGroup?.categories : [];
  const targets = tableData.targets;

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <FullHeightSpinner />
        </div>
      ) : (
        <>
          <table className="table table-bordered">
            <thead>
              {hasSubGroups && (
                <tr>
                  <th>Target</th>
                  {categories?.map((category) => (
                    <th key={category.text}>{category.text}</th>
                  ))}
                </tr>
              )}
            </thead>
            <tbody>
              {hasSubGroups && (
                <>
                  <tr>
                    <td className="text-align-left">Total weight base</td>
                    <td
                      colSpan={categories?.length}
                      className="text-align-center font-weight-bold"
                    >
                      {isEditable ? (
                        <NumericTextBox
                          value={tableData.targetPopulation}
                          spinners={false}
                          min={0}
                          onChange={(e) => {
                            const newValue = e.value !== null ? e.value : null;
                            setTableData((prevData) => ({
                              ...prevData,
                              targetPopulation: newValue,
                            }));
                          }}
                          onKeyDown={preventKeyDown}
                          rounded={null}
                          style={{ width: '100%' }}
                        />
                      ) : (
                        <span>{tableData.targetPopulation ?? '-'}</span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-align-left">Proportion of total</td>
                    {categories?.map((category, catIndex) => (
                      <td key={`proportion-${category.text}`}>
                        {isEditable ? (
                          <NumericTextBox
                            value={
                              category.targetBasePct !== undefined && category.targetBasePct !== ''
                                ? Number(category.targetBasePct)
                                : null
                            }
                            spinners={false}
                            min={0}
                            onChange={(e) => {
                              const newValue =
                                e.value != null ? e.value.toString() : '';
                              setTableData((prevData) => {
                                const updatedCategories = prevData.subGroup
                                  ? [...prevData.subGroup.categories]
                                  : [];
                                updatedCategories[catIndex] = {
                                  ...updatedCategories[catIndex],
                                  targetBasePct: newValue,
                                };
                                return {
                                  ...prevData,
                                  subGroup: {
                                    ...prevData.subGroup,
                                    categories: updatedCategories,
                                  },
                                };
                              });
                            }}
                            onKeyDown={preventKeyDown}
                            rounded={null}
                          />
                        ) : (
                          <span>
                            {category.targetBasePct ? `${category.targetBasePct}%` : '-'}
                          </span>
                        )}
                      </td>
                    ))}
                  </tr>
                </>
              )}
              {targets.map((target, targetIdx) => (
                <React.Fragment key={target.id}>
                  <tr>
                    <td
                      colSpan={hasSubGroups ? (categories?.length ?? 0) + 1 : 1}
                      className="font-weight-bold text-align-left d-flex align-items-center justify-content-between"
                    >
                      {target.id}
                      {isEditable && onRemoveTarget && (
                        <Button
                          size="small"
                          className="text-danger ml-2"
                          type="button"
                          onClick={() => onRemoveTarget(target.id)}
                        >
                          - Remove Target
                        </Button>
                      )}
                    </td>
                  </tr>
                  {target.targetCells.map((targetCell, targetCellIdx) => (
                    <tr key={`${targetIdx}-${targetCell.text}`}>
                      <td className="text-align-left">{targetCell.text}</td>
                      {hasSubGroups ? (
                        categories?.map((category, catIndex) => {
                          const targetReference = category.targetReferences.find(
                            (ref) => ref.id === target.id
                          );

                          if (
                            targetReference &&
                            targetReference.proportions.length > targetCellIdx
                          ) {
                            const proportion = targetReference.proportions[targetCellIdx];

                            return (
                              <td key={`${catIndex}-${targetCell.text}`}>
                                {isEditable ? (
                                  <NumericTextBox
                                    rounded={null}
                                    value={
                                      proportion.pct !== undefined && proportion.pct !== ''
                                        ? Number(proportion.pct)
                                        : null
                                    }
                                    spinners={false}
                                    min={0}
                                    onKeyDown={preventKeyDown}
                                    onChange={(e) => {
                                      const newValue =
                                        e.value != null ? e.value.toString() : '';
                                      setTableData((prevData) => {
                                        const updatedData = { ...prevData };
                                        const updatedCategories = updatedData.subGroup
                                          ? [...updatedData.subGroup.categories]
                                          : [];
                                        const updatedCategory = {
                                          ...updatedCategories[catIndex],
                                        };
                                        const updatedTargetReferences = [
                                          ...updatedCategory.targetReferences,
                                        ];
                                        const targetRefIndex = updatedTargetReferences.findIndex(
                                          (ref) => ref.id === target.id
                                        );
                                        if (targetRefIndex !== -1) {
                                          const updatedTargetReference = {
                                            ...updatedTargetReferences[targetRefIndex],
                                          };
                                          const updatedProportions = [
                                            ...updatedTargetReference.proportions,
                                          ];
                                          updatedProportions[targetCellIdx] = {
                                            pct: newValue,
                                          };
                                          updatedTargetReference.proportions = updatedProportions;
                                          updatedTargetReferences[
                                            targetRefIndex
                                          ] = updatedTargetReference;
                                          updatedCategory.targetReferences = updatedTargetReferences;
                                          updatedCategories[catIndex] = updatedCategory;
                                          if (updatedData.subGroup) {
                                            updatedData.subGroup.categories = updatedCategories;
                                          }
                                        }
                                        return updatedData;
                                      });
                                    }}
                                  />
                                ) : (
                                  <span>{proportion.pct ? `${proportion.pct}%` : '-'}</span>
                                )}
                              </td>
                            );
                          }
                          return <td key={`${catIndex}-${targetCell.text}`}>-</td>;
                        })
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          {isEditable && (
            <Button
              type="button"
              onClick={onValidate}
              className="btn btn-outline-dark d-flex mb-4"
              disabled={isValidating}
            >
              {isValidating && (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Validate
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default AdvancedWeightingTable;
