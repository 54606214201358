import { FullHeightSpinner } from '../../../../../../../shared/FullHeightSpinner/FullHeightSpinner';
import AdvancedWeightingTable from './AdvancedWeightingTable';
import { mapRoundDirection } from './utils/utils';

interface StepFiveProps {
  templateData: TemplateData | null;
  roundingValues: Rounding;
  capping: { [categoryText: string]: { minCap: number | null; maxCap: number | null } } | null;
  isLoading: boolean;
  weightName: string;
  weightDescription: string;
  maxIterations?: number | null;
}

const StepFive = ({ templateData, roundingValues, capping, weightName, weightDescription, isLoading, maxIterations }: StepFiveProps) => {
  if (!templateData) {
    return null;
  }
  const categories = templateData.subGroup?.categories || [];

  return (
    <div>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <FullHeightSpinner />
        </div>
      ) : (
        <>
          <div>
            <AdvancedWeightingTable isEditable={false} data={templateData} />
          </div>

          <div className='mb-4'>
            <div className='font-weight-bold'>Caping</div>
            {capping && Object.keys(capping).length > 0 ? (
              <div className="pt-8">
                <div className="border p-4">
                  <p className="font-weight-bold">Cap Weights</p>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th />
                        {categories.map(category => (
                          <th className="text-center" key={category.text}>{category.text}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className='text-align-left'>Min Cap</td>
                        {categories.map(category => (
                          <td key={category.text}>
                            {capping[category.text]?.minCap != null ? capping[category.text].minCap : '-'}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className='text-align-left'>Max Cap</td>
                        {categories.map(category => (
                          <td key={category.text}>
                            {capping[category.text]?.maxCap != null ? capping[category.text].maxCap : '-'}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ) : <div className='font-italic small text-gray'>Off</div>}
          </div>


          <div className="d-flex flex-column">
            <table>
              <thead className="text-align-left">
                <tr className="font-weight-bold">
                  <th colSpan={2}>Rounding</th>
                </tr>
              </thead>

              {roundingValues.enabled ? (
                <tbody>
                  <tr className="text-left">

                    <td className="text-left w-50">Decimal place for rounding</td>
                    <td className="text-left">{roundingValues.decimals}</td>
                  </tr>
                  <tr>
                    <td className="text-left w-50">Rounding direction</td>
                    <td className="text-left">{mapRoundDirection(roundingValues.roundingDirection)}</td>
                  </tr>
                </tbody>) : (<tbody className='font-italic small text-gray'>Off</tbody>)
              }
            </table>

            <table className="mt-4">
              <thead className="text-align-left">
                <tr className="font-weight-bold">
                  <th colSpan={2}>Number of Iterations</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-left">
                  <td className="text-left w-50">The total number of Iterations that should run</td>
                  <td className="text-left">{maxIterations}</td>
                </tr>

              </tbody>
            </table>

            <table className="mt-4">
              <thead className="text-align-left">
                <tr className="font-weight-bold">
                  <th colSpan={2}>Weighting Scheme name</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-left">
                  <td className="text-left w-50">Name</td>
                  <td className="text-left">{weightName}</td>
                </tr>
                <tr>
                  <td className="text-left w-50">Description</td>
                  <td className="text-left">{weightDescription || '-'}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default StepFive;
