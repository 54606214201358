import { useEffect, useState } from "react";
import JsxParser from 'react-jsx-parser';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import type { RootState } from "../../../../../../store/reducers/rootReducer";
import { FullHeightSpinner } from "../../../../../shared/FullHeightSpinner/FullHeightSpinner";
import { HorizontalBarChart } from "../../../../../Analysis/Analyze/components/dashboard/main/TabContent/ChartTabContent/ChartTypeContent/HorizontalBarChart/HorizontalBarChart";
import type { ChartData } from "../../../../../../interfaces/chartTypes/horizontalBarChartTypes";
import type { SubQuestion, SubQuestionElements } from "../../../../../../interfaces/surveyInterfaces/surveyBuilderTypes";
import { fetchPostJson } from "../../../../../../services/services";

type TableResData = {
  analysisType: "topline" | "crosstab";
  type: "html";
  result: string;
}

type ChartResData = {
  analysisType: "chart";
  type: "json";
  result: ChartData[];
}

export const AnalysisTypes = ["topline", "chart", "crosstab"] as const;
export type AnalysisTypesType = (typeof AnalysisTypes)[number];

export const ToplineTabContent = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer));
  const params: { name: string, survey: string } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState<TableResData | null>(null);
  const [chartData, setChartData] = useState<ChartResData | null>(null);

  const selectedSubQuestionElement = theData.selectedItem.elements.filter((item: SubQuestion) => item.type === "subq")[theData.selectedSubquestion] as unknown as SubQuestionElements;

  useEffect(() => {
    if (theData.selectedItem.published && theData.selectedItem.attributes?.['miext:id'] && selectedSubQuestionElement.attributes?.['miext:id']) {
      const body = {
        quesMiextId: theData.selectedItem.attributes?.['miext:id'],
        subqMiextId: selectedSubQuestionElement.attributes?.['miext:id']
      }

      fetchPostJson(`an/projects/${params.name}/analysis/surveys/${params.survey}/question-overview`, token, body)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
          } else {
            const tableRes = res.find((item: { analysisType: AnalysisTypesType }) => item.analysisType === "topline" || item.analysisType === "crosstab");
            const chartRes = res.find((item: { analysisType: AnalysisTypesType }) => item.analysisType === "chart");

            const formatedChartRes = chartRes ? {
              ...chartRes,
              result: [JSON.parse(chartRes.result)]
            } : null;

            setTableData(tableRes);
            setChartData(formatedChartRes);
            setIsLoading(false);
          }
        })
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Publish survey" } });
      setIsLoading(false);
    }
  }, [params, dispatch, token, theData.selectedItem, selectedSubQuestionElement.attributes?.['miext:id']]);

  return (
    <div className="w-100 h-100 d-flex flex-column overflow-hidden bg-white topline">
      {!selectedSubQuestionElement.attributes?.['miext:id'] && (
        <div className="d-flex flex-column p-4 justify-content-center align-items-center h-50">
          <p>Publish survey</p>
        </div>
      )}

      {
        isLoading ?
          <FullHeightSpinner />
          :
          <div className="d-flex flex-column gap-lg">
            {tableData && (
              < JsxParser jsx={tableData.result} />
            )}

            {chartData && (
              <HorizontalBarChart data={chartData?.result} zoomLevel={1} />
            )}
          </div>
      }
    </div>
  )
}