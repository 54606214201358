import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, Link, NavLink } from 'react-router-dom';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../shared/Icon/Icon';
import { SideBar } from '../shared/SideBar/SideBar';
import { InboxDrawer } from '../shared/InboxDrawer/InboxDrawer';
import { Breadcrumb } from '../shared/Breadcrumb/Breadcrumb';
import type { RootState } from '../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../shared/FullHeightSpinner/FullHeightSpinner';
import { WorkflowDesignTabContent } from './WorkflowTabContent/WorkflowDesignTabContent/WorkflowDesignTabContent';
import { fetchGetJson as getWorkflow, fetchPostResOrJson as postWorkflow, fetchPutJson as updateTool } from '../../services/services'

export const Workflow = () => {
  document.title = "Data Operations – Walr";
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);
  const [didMount, setDidMount] = useState<boolean>(true)

  const dispatch = useDispatch();
  const location = useLocation();
  const params: { name: string, workflow: string } = useParams();
  const [showActivitySidebar, setShowActivitySidebar] = useState(false);

  const locationItems = location.pathname.split('/');
  const activeRouteTitle = locationItems[locationItems.length - 1];
  const projectId = params.name
  const workflowId = params.workflow

  useEffect(() => {
    if (didMount) {
      setDidMount(false)
      getWorkflow(`projects/${projectId}/workflows/${workflowId}`, token)
        .then((res: TODO) => {
          if (res && !res.error && !res.message) {
            dispatch({ type: 'SET_WORKFLOW_INITIAL_DATA', payload: res })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
          }
        })
    }
  }, [dispatch, projectId, workflowId, didMount, token])

  const runWorkflow = () => {
    if (workflowData.selectedTool) {
      if (workflowData.selectedTool.isCustom) {
        dispatch({ type: "SET_RUN_CUSTOM_WORKFLOW", payload: true });
      } else {
        if (workflowData.selectedTool.toolType === "Append" && workflowData.selectedTool.mergeMethod === "NotSet") {
          dispatch({ type: 'APPEND_MATCH_MEGE_ERROR', payload: true })
          return
        }

        if (workflowData.selectedTool.toolType === "Append" && (workflowData.selectedTool.compareTypeA === "NotSet" || workflowData.selectedTool.compareTypeB === "NotSet")) {
          dispatch({ type: 'APPEND_MATCH_BY_ERROR', payload: true })
          return
        }

        postWorkflow(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}`, token)
          .then((res: TODO) => {
            if (res.status === 202) {
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Processing workflow...' } })
            } else if (res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
            }
          })
      }
    }
  }

  const onSaveChanges = () => {
    const body = workflowData.selectedTool
    if (workflowData.selectedTool) {
      if (workflowData.selectedTool.isCustom) {
        dispatch({ type: "SET_SAVE_CUSTOM_WORKFLOW", payload: true });
      } else {
        if (body.toolType === "Append" && body.mergeMethod === "NotSet") {
          dispatch({ type: 'APPEND_MATCH_MEGE_ERROR', payload: true })
          return
        }

        if (body.toolType === "Append" && (body.compareTypeA === "NotSet" || body.compareTypeB === "NotSet")) {
          dispatch({ type: 'APPEND_MATCH_BY_ERROR', payload: true })
          return
        }

        updateTool(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}/tools/${workflowData.selectedTool.id}`, token, body)
          .then((res: TODO) => {
            if (res && !res.message && !res.error) {
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The tool has been saved successfully' } })
              dispatch({ type: 'SET_TOOL_SELECTED', payload: res })
            } else {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
            }
          })
      }
    }
  }

  return (
    <section className="main do-container d-flex flex-column overflow-hidden flex-fill w-100 vh-100 p-0">
      {
        showActivitySidebar &&
        <div className='sidebar-activity'>
          <SideBar width="400px" onHide={() => setShowActivitySidebar(false)}>
            <InboxDrawer />
          </SideBar>
        </div>
      }
      {!didMount && workflowData.data !== null ?
        <Fragment>
          <div className="app-header">
            <Link
              to={{
                pathname: `${location.pathname
                  .split('/')
                  .slice(0, location.pathname.length - (location.pathname.length - (2)))
                  .join('/')}/${location.pathname.split('/')[2]}`,
              }}
              className='d-flex back-to-project justify-content-center align-items-center w-56 border-right cursor-pointer'>

              <div className='walr'><Icon className='walr pe-none' type="walr" /></div>
              <div className='exit'><Icon className='exit pe-none' type="exit" /></div>
              <span className='tooltip'>Back to project</span>
            </Link>
            <Breadcrumb
              routePath={location.pathname}
              activeRouteTitle={activeRouteTitle}
            />
            <div style={{ position: 'absolute', right: '0', minHeight: '3.5rem' }} className='d-flex align-items-center mr-3'>
              {
                (window.location.hostname === 'localhost' || window.location.hostname === 'platform-staging.walr.com' || window.location.hostname === 'platform-preprod.walr.com') &&
                <NavLink to="/custom-operations" type='button' className='btn btn-shadow mr-2'>Custom operations</NavLink>
              }

              <Tooltip anchorElement="target" position="bottom">
                <button type='button' className='btn btn-shadow btn-icon icon-b mr-2' title="Show activity" onClick={() => setShowActivitySidebar(prevState => !prevState)}>
                  <Icon type='nav-activity-mini' className='pe-none' />
                </button>
              </Tooltip>

              <button type='button' disabled={!workflowData.selectedTool} onClick={onSaveChanges} className="btn btn-shadow mr-2">Save</button>
              <button type='button' className="btn btn-primary btn-icon icon-l" onClick={runWorkflow}><Icon type="run" />Run workflow</button>
            </div>
          </div>
          <WorkflowDesignTabContent />
        </Fragment >
        :
        <FullHeightSpinner />}
    </section >
  )
}
