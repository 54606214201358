import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import { Icon } from '../../../../../../shared/Icon/Icon';
import { SurveyElementsTable } from '../SurveyElementsTable/SurveyElementsTable';
import { useDebounce } from '../../../../../../shared/customHooks/useDebounce';
import { SurveyFilterBuilder } from '../../../SurveyFilterBuilder/SurveyFilterBuilder';
import { DropdownButton } from '../../../../../../shared/DropdownButton/DropdownButton';
import { checkRotationType } from '../../../helpers/returnDataHelpers/returnDataHelpers';
import SurveyQuillEditor from "../../../../../../shared/SurveyQuillEditor/SurveyQuillEditor"
import { returnReversedIndex } from '../../../../../../shared/SurveyQuillEditor/SurveyQuillEditorHelpers';
import { TextFilterDropdownButton } from '../../../helpers/TextFilterDropdownButton/TextFilterDropdownButton';
import { AnswerLayoutValidationErrorMessage } from '../../../helpers/AnswerLayoutValidationErrorMessage/AnswerLayoutValidationErrorMessage';
import { PreviewTabContent } from '../../PreviewTabContent/PreviewTabContent';
import { ToplineTabContent } from '../../ToplineTabContent/ToplineTabContent';
import { cleanHtmlEntities } from '../../../../../../shared/helpers/cleanHtmlEntities/cleanHtmlEntities'
import { fetchGetJson as getSurveyStructure } from '../../../../../../../services/services';

export const SurveySubquestionsTabs = ({ onChangeSubquestion, onDragEnd, handleAction, onAddElement, onAdvancedEdit, onShowScript, onSaveChanges }) => {
  const { theData } = useSelector((theState) => (theState.surveyInitialDataReducer))
  const { token } = useSelector((state) => state.tokenStateReducer);
  const params = useParams()
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState('edit')
  const [showFilterBuilder, setShowFilterBuilder] = useState({ display: false, filterData: [], primaryIndex: null, secondaryIndex: null })
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);

  const findSelectedText = (textData) => {
    if (theData.dropdownValues.length > 0) {
      const textMatch = theData.dropdownValues.find(item => item.name === 'textType').enumValues.find(item => item.name === textData.attributes.textType)
      if (textMatch) {
        return textMatch.text
      }
      return ' '
    }
    return ' '
  }

  const onQTextTypeChange = (item, textIndex) => {
    if (item.text === 'Add text filter') {
      showFilterBuilderHandler('filter', 'qtext', textIndex)
    } else {
      const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
      const attributesCopy = { ...updatedSelectedItem.elements.find(item => item.type === 'qtext').elements[textIndex].attributes }
      updatedSelectedItem.elements.find(item => item.type === 'qtext').elements[textIndex].attributes = { ...attributesCopy, textType: item.name }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      dispatch({ type: 'SAVE_QUESTION' })
    }
  }

  const onTextTypeChange = (item, textIndex, subquestionIndex) => {
    if (item.text === 'Add text filter') {
      showFilterBuilderHandler('filter', 'stext', textIndex)
    } else {
      const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
      const attributesCopy = { ...updatedSelectedItem.elements.filter(item => item.type === 'subq')[subquestionIndex].elements.find(item => item.type === 'stext').elements.filter(item => item.type === 'text')[textIndex].attributes }
      updatedSelectedItem.elements.filter(item => item.type === 'subq')[subquestionIndex].elements.find(item => item.type === 'stext').elements.filter(item => item.type === 'text')[textIndex].attributes = { ...attributesCopy, textType: item.name }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      dispatch({ type: 'SAVE_QUESTION' })
    }
  }

  const onAddDeleteSubqTextHandler = (action, textIndex) => {
    if (action === 'add') {
      handleAction({ item: { id: action } }, theData.selectedSubquestion, 0, 'subq')
    } else {
      const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
      updatedSelectedItem.elements.filter(item => item.type === 'subq')[theData.selectedSubquestion].elements.find(item => item.type === 'stext').elements.splice(textIndex, 1)
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      saveWithDebounce()
    }
  }

  const onAddDeleteQTextHandler = (action, textIndex) => {
    if (action === 'add') {
      const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
      updatedSelectedItem.elements.find(item => item.type === 'qtext').elements.push({ "type": "text", "attributes": {}, "textValues": { [theData.editingLanguage]: "" } })
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      saveWithDebounce()
    } else {
      const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
      if (textIndex === 0) {
        const indexOfDeleted = updatedSelectedItem.elements.findIndex(item => item.type === 'qtext')
        updatedSelectedItem.elements.splice(indexOfDeleted, 1)
      } else {
        updatedSelectedItem.elements.find(item => item.type === 'qtext').elements.splice(textIndex, 1)
      }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      saveWithDebounce()
    }
  }

  const onTextChangeHandler = (value, elementTextIndex, subquestionIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedSelectedItem.elements.filter(item => item.type === 'subq')[subquestionIndex].elements.find(item => item.type === 'stext').elements.filter(item => item.type === 'text')[elementTextIndex].textValues[theData.editingLanguage] = cleanHtmlEntities(value)
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const onChangeQuestionTitle = (value) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedSelectedItem.attributes.id = value
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const showFilterBuilderHandler = (option, type, primaryIndex, secondaryIndex, expression) => {
    const element = JSON.parse(JSON.stringify(theData.originalSelectedItem))
    getSurveyStructure(`su/projects/${params.name}/surveys/${params.survey}/structure`, token)
      .then((res) => {
        if (res && !res.error && !res.message) {
          setShowFilterBuilder({
            display: true,
            filterData: res,
            type: type,
            primaryIndex: primaryIndex,
            secondaryIndex: secondaryIndex,
            filterBuilderOptionId: option,
            manualFilterExpression: expression,
            combineFilterData: element.metadata?.filterData ? element.metadata.filterData : []
          })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
        }
      })
  }

  const onSaveTextFilter = (expression) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    const { type, primaryIndex, secondaryIndex } = showFilterBuilder
    if (type === 'stext') {
      updatedSelectedItem.elements.filter(el => el.type === 'subq')[theData.selectedSubquestion].elements.find(el => el.type === 'stext').elements[primaryIndex].attributes.filter = expression
    } else if (type === 'qtext') {
      updatedSelectedItem.elements.find(el => el.type === 'qtext').elements[primaryIndex].attributes.filter = expression
    } else if (type === 'r') {
      updatedSelectedItem.elements.find(el => el.type === 'rgroup').elements[primaryIndex].elements[secondaryIndex].attributes.filter = expression
    } else if (type === 'h') {
      updatedSelectedItem.elements.filter(el => el.type === 'subq')[theData.selectedSubquestion].elements.find(el => el.type === 'hgroup').elements[primaryIndex].elements[secondaryIndex].attributes.filter = expression
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    dispatch({ type: 'SAVE_QUESTION' })
    setShowFilterBuilder({ display: false, filterData: [], primaryIndex: null, secondaryIndex: null, manualFilterExpression: null })
  }

  const onChangeIntroText = (value, textIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedSelectedItem.elements.find(el => el.type === 'qtext').elements[textIndex].textValues[theData.editingLanguage] = value
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  return (
    <Fragment>
      {
        showFilterBuilder.display &&
        <SurveyFilterBuilder
          handleClose={() => setShowFilterBuilder({ display: false, filterData: [], primaryIndex: null, secondaryIndex: null })}
          filterData={showFilterBuilder.filterData}
          surveyId={theData.originalData.surveyId}
          manualFilterExpression={showFilterBuilder.manualFilterExpression}
          combineFilterData={[]}
          option='filter'
          token={token}
          type="textElements"
          onSaveTextFilter={onSaveTextFilter}
        />
      }
      <TabStrip selected={theData.selectedSubquestion} onSelect={(e) => onChangeSubquestion(e.selected)} className='preview-device-tabs'>
        {
          theData.selectedItem?.elements?.filter(el => el.type === 'subq').map((subquestion, subquestionKey) => (
            <TabStripTab key={subquestionKey} title="subquestion">
              {/* HEADER */}
              <div className="card-header d-flex justify-content-between align-items-center py-0 pl-2 pr-3">
                <div className="d-flex align-items-center flex-grow-1 h-100">
                  {
                    subquestion.errorMessage !== "" &&
                    <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                      <AnswerLayoutValidationErrorMessage errorMessage={subquestion.errorMessage} />
                    </Tooltip>
                  }
                  <input
                    name='surveyQuestionTitle'
                    type="text"
                    style={{ minWidth: '150px' }}
                    className='form-control survey-question-title'
                    value={theData.selectedItem.attributes.id}
                    onChange={(e) => onChangeQuestionTitle(e.target.value)}
                  />
                </div>
                <ul className="nav nav-pills flex-nowrap content-tabs ml-3 tabs-48">
                  <li onClick={() => setSelectedTab('edit')} className="nav-item">
                    <span className={`nav-link ${selectedTab === 'edit' ? 'active' : ''}`}>Edit</span>
                  </li>

                  <li onClick={() => setSelectedTab('preview')} className="nav-item">
                    <span className={`nav-link ${selectedTab === 'preview' ? 'active' : ''}`}>Preview</span>
                  </li>

                  <li onClick={() => setSelectedTab('topline')} className="nav-item">
                    <span className={`nav-link ${selectedTab === 'topline' ? 'active' : ''}`}>Topline</span>
                  </li>
                </ul>
              </div>

              {selectedTab === "edit" && (
                <div className={`h-100 w-100 py-5 px-7 position-relative d-flex flex-column gap-md ${(theData.selectedItem.disabled || subquestion.disabled) && "blur"}`}>
                  {/* CONTAINER TEXT (QTEXT) */}
                  {
                    theData.selectedItem?.elements?.find(el => el.type === 'qtext') &&
                    <div className='survey-selectedSubQuestion container-text'>
                      <div className="card-body p-0">
                        <ul className="list-group list-group-flush">
                          {
                            theData.selectedItem?.elements?.find(el => el.type === 'qtext')?.elements.filter((el) => el.type === 'text')?.map((text, key) => (
                              <li key={key} className="list-group-item d-flex justify-content-between align-items-center flex-grow-1 border-bottom inline-editor p-0">
                                <div className="d-flex align-items-center w-100">
                                  {!theData.htmlMode ?
                                    <SurveyQuillEditor
                                      token={token}
                                      value={text.textValues[theData.editingLanguage]}
                                      setValue={(e) => onChangeIntroText(e, key)}
                                      orderNumber={returnReversedIndex(theData.selectedItem?.elements?.find(el => el.type === 'qtext')?.elements.filter((el) => el.type === 'text').length + 10, key)}
                                      selectedItemId={theData.selectedItem.id}
                                      placeholder={text.attributes?.textType === 'do' ? 'Enter instruction text...' : text.attributes?.textType === 'say' ? 'Enter supplementary text...' : 'Enter container text...'} />
                                    :
                                    <TextareaAutosize
                                      cacheMeasurements
                                      style={{ resize: 'none' }}
                                      className='form-control autosize border-0 shadow-none question-text'
                                      value={text.textValues[theData.editingLanguage]}
                                      onChange={(e) => onChangeIntroText(e.target.value, key)}
                                      placeholder={text.attributes?.textType === 'do' ? 'Enter instruction text...' : text.attributes?.textType === 'say' ? 'Enter supplementary text...' : 'Enter container text...'} />
                                  }
                                </div>
                                <div className="d-flex align-items-center cursor-default">
                                  <div className='d-flex small strong rounded text-survey-dark-blue text-nowrap btn-survey-light-blue h-24 align-items-center px-2 pe-none'>Container text</div>
                                  {
                                    theData.dropdownValues.length > 0 &&
                                    <DropdownButton
                                      items={[...theData.dropdownValues.find(item => item.name === 'textType')?.enumValues, { text: 'separator' }, { text: 'separator' }, { text: 'Add text filter' }]}
                                      className={`btn-secondary p-0 mx-2 ${findSelectedText(text) === ' ' ? '' : 'pl-2'}`}
                                      text={findSelectedText(text)}
                                      onItemClick={(e) => onQTextTypeChange(e.item, key)}
                                    />
                                  }
                                  {
                                    text.attributes?.filter && theData.showLogic ?
                                      <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                        <TextFilterDropdownButton
                                          text={text}
                                          showLogic={theData.showLogic}
                                          type="qtext"
                                          itemIndex={key}
                                          showFilterBuilderHandler={showFilterBuilderHandler} />
                                      </Tooltip>
                                      :
                                      <button
                                        type='button'
                                        onClick={() => showFilterBuilderHandler('filter', "qtext", key, null, text.attributes?.filter)}
                                        className="btn btn-secondary py-1 px-2 filter">F
                                      </button>
                                  }
                                  {
                                    key === 0 &&
                                    <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                      <button type='button' onClick={() => onAddDeleteQTextHandler('add', key)} className="btn btn-secondary p-0 ml-2 border-0">
                                        <span title={'Add container text'}><Icon className='pe-none' type={"add"} /></span>
                                      </button>
                                    </Tooltip>
                                  }
                                  <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                    <button type="button" onClick={() => onAddDeleteQTextHandler('delete', key)} className="btn btn-secondary p-0 ml-2 border-0">
                                      <span title={'Remove container text'}><Icon className='pe-none' type={'minus'} /></span>
                                    </button>
                                  </Tooltip>
                                </div>
                              </li>
                            ))
                          }
                        </ul>
                      </div>
                    </div>
                  }
                  {/* QUESTION TITLE */}
                  {
                    subquestion?.elements?.length > 0 && subquestion.elements.find(el => el.type === 'stext').elements.filter(el => el.type === 'text').map((text, key) => (
                      <div key={key} className="d-flex survey-selectedSubQuestion w-100 border-bottom">
                        <div className="d-flex justify-content-between w-100">
                          {
                            !theData.htmlMode ?
                              <SurveyQuillEditor
                                token={token}
                                value={text.textValues[theData.editingLanguage]}
                                setValue={(e) => onTextChangeHandler(e, key, subquestionKey)}
                                orderNumber={returnReversedIndex(subquestion.elements.length > 0 && subquestion.elements.find(el => el.type === 'stext').elements.filter(el => el.type === 'text').length, key)}
                                selectedItemId={theData.selectedItem.id}
                                classes="pl-0"
                                placeholder={text.attributes?.textType === 'do' ? 'Enter instruction text...' : text.attributes?.textType === 'say' ? 'Enter supplementary text...' : 'Enter question text...'} />
                              :
                              <TextareaAutosize
                                cacheMeasurements
                                style={{ resize: 'none' }}
                                className='form-control autosize border-0 shadow-none question-text'
                                value={text.textValues[theData.editingLanguage]}
                                onClick={() => dispatch({ type: 'SET_SELECTED_ELEMENT', payload: subquestion })}
                                onChange={(e) => onTextChangeHandler(e.target.value, key, subquestionKey)}
                                placeholder={text.attributes?.textType === 'do' ? 'Enter instruction text...' : text.attributes?.textType === 'say' ? 'Enter supplementary text...' : 'Enter question text...'} />
                          }
                          <div className="d-flex align-items-center flex-shrink-0">
                            {
                              key === 0 && subquestion.elements.find(el => el.type === 'stext').errorMessage !== "" &&
                              <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                <AnswerLayoutValidationErrorMessage errorMessage={subquestion.elements.find(el => el.type === 'stext').errorMessage} className="mr-2" />
                              </Tooltip>
                            }
                            {
                              theData.dropdownValues.length > 0 &&
                              <DropdownButton
                                items={[...theData.dropdownValues.find(item => item.name === 'textType')?.enumValues, { text: 'separator' }, { text: 'Add text filter' }]}
                                className={`btn-secondary p-0 mr-2 ${findSelectedText(text) === ' ' ? '' : 'pl-2'}`}
                                text={findSelectedText(text)}
                                onItemClick={(e) => onTextTypeChange(e.item, key, subquestionKey)}
                              />
                            }
                            {
                              text.attributes?.filter && theData.showLogic ?
                                <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                  <TextFilterDropdownButton
                                    text={text}
                                    showLogic={theData.showLogic}
                                    type="stext"
                                    itemIndex={key}
                                    showFilterBuilderHandler={showFilterBuilderHandler} />
                                </Tooltip>
                                :
                                <button
                                  type='button'
                                  onClick={() => showFilterBuilderHandler('filter', "stext", key, null, text.attributes?.filter)}
                                  className="btn btn-secondary py-1 px-2 filter">F
                                </button>
                            }
                            <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                              <button type='button' onClick={() => onAddDeleteSubqTextHandler(key === 0 ? 'add' : 'delete', key)} className="btn btn-secondary p-0 ml-2 border-0">
                                <span title={`${key === 0 ? 'Add question text' : 'Remove question text'}`}><Icon className='pe-none' type={`${key === 0 ? 'add' : 'minus'}`} /></span>
                              </button>
                            </Tooltip>
                          </div>
                        </div >
                      </div >
                    ))
                  }

                  {/* BADGES */}
                  <div className='d-flex flex-column'>
                    <div className="d-flex flex-wrap align-items-start survey-badges gap-sm">
                      {subquestion.attributes?.filter && theData.showLogic &&
                        <div className="text-analyze small strong p-0">
                          <i className="fas fa-filter fa-sm mr-2" />
                          Filter={subquestion.attributes.filter}
                        </div>
                      }
                      {subquestion.attributes?.range ?
                        <span className="text-analyze small strong p-0">Answer required
                          {subquestion.attributes.range !== '*' && ` = ${subquestion.attributes.range}`}
                        </span>
                        :
                        <span className="text-analyze small strong p-0">Answer not required</span>
                      }
                      {checkRotationType(theData.selectedItem.elements.find(el => el.type === 'rgroup').elements).rotationType !== 0 && theData.showLogic &&
                        <div className="d-flex align-items-center text-analyze small strong p-0">
                          <i className="fas fa-sync-alt fa-sm mr-1" />
                          {checkRotationType(theData.selectedItem.elements.find(el => el.type === 'rgroup').elements).rotationType === 1 ? "Random" : "Advanced"} randomisation
                        </div>
                      }
                    </div>

                    {subquestion.attributes?.afill && theData.showLogic &&
                      <div className="text-analyze small strong p-0">
                        Answer={subquestion.attributes.afill}
                      </div>
                    }
                  </div>

                  <SurveyElementsTable
                    subquestion={subquestion}
                    onDragEnd={onDragEnd}
                    onAddElement={onAddElement}
                    onAdvancedEdit={onAdvancedEdit}
                    onSaveChanges={onSaveChanges}
                    showFilterBuilderHandler={showFilterBuilderHandler}
                  />
                </div>
              )}

              {selectedTab === "preview" && (
                <PreviewTabContent
                  type='edit'
                  onShowScript={onShowScript}
                />
              )}

              {
                selectedTab === "topline" && (
                  <ToplineTabContent />
                )
              }

            </TabStripTab >
          ))
        }
      </TabStrip >
    </Fragment >
  )
}