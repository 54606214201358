import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ActionsButtons from './ActionButtons/ActionButtons';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import StepSix from './StepSix';
import { fetchPostJson } from '../../../../../../../../services/services';

const TOTAL_STEPS = 6;

const isWeightNameValid = (weightName: string): boolean => {
  const regex = /^[a-zA-Z0-9_]+$/;
  return regex.test(weightName);
}

interface AdvancedWeightingWizardProps {
  token: string;
  datasetId: string;
  onClose: () => void;
}

const AdvancedWeightingWizard = ({ token, datasetId, onClose }: AdvancedWeightingWizardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const projectId = history.location.pathname.split('/')[2];

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [universeDescription, setUniverseDescription] = useState<string>('');
  const [universeDefinition, setUniverseDefinition] = useState<string>('');
  const [weightName, setWeightName] = useState<string>('');
  const [weightDescription, setWeightDescription] = useState<string>('');
  const [createUniverse, setCreateUniverse] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mainTemplateData, setMainTemplateData] = useState<TemplateData | null>(null);
  const [weightResult, setWeightResult] = useState<WeightResult>();
  const [roundingValues, setRoundingValues] = useState<Rounding>({
    enabled: false,
    decimals: 0,
    roundingDirection: 0,
    roundingErrorDistribution: 0,
  });
  const [shouldValidate, setShouldValidate] = useState<boolean>(true);
  const [capWeights, setCapWeights] = useState<{ [categoryText: string]: { minCap: number | null; maxCap: number | null } }>({});
  const [maxIterations, setMaxIterations] = useState<number | null>(null);

  const handleNext = async (): Promise<void> => {
    switch (currentStep) {
      case 1:
        if (isWeightNameValid(weightName)) {
          setCurrentStep(2);
        } else {
          dispatch({
            type: 'SHOW_ERROR_NOTIFICATION',
            payload: { msg: 'Weight name contains illegal characters. Allowed characters are a-z, A-Z, _ and 0-9', timeout: 10000 },
          });
        }
        break;
      case 2:
        if (mainTemplateData) {
          setCurrentStep(3);
        }
        break;
      case 3:
        setCurrentStep(4);
        break;
      case 4:
        setCurrentStep(5);
        break;
      case 5:
        if (mainTemplateData) {
          createWeightDryRun();
        }
        break;
      case 6:
        if (weightResult) {
          createWeight();
        }
        break;
      default:
        if (currentStep < TOTAL_STEPS) {
          setCurrentStep(currentStep + 1);
        }
        break;
    }
  };

  const handleNextDisabled = (): boolean => {
    switch (currentStep) {
      case 1:
        return !weightName || isLoading;
      case 2:
        return shouldValidate || !mainTemplateData || isLoading;
      default:
        return isLoading;
    }
  };

  const isNextDisabled = handleNextDisabled();

  const handleBack = (): void => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const validateTemplateData = async (template: TemplateData) => {
    setIsLoading(true);
    const payload = { ...template, id: weightName, description: weightDescription };
    try {
      const response = await fetchPostJson(
        `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced/validate`,
        token,
        payload,
      );

      if (response.length > 0) {
        throw response[0];
      }
      setIsLoading(false);
      setShouldValidate(false);
      dispatch({
        type: 'SHOW_SUCCESS_NOTIFICATION',
        payload: { msg: 'Template Validated' },
      });
      return { validate: true };
    } catch (error) {
      setIsLoading(false);
      dispatch({
        type: 'SHOW_ERROR_NOTIFICATION',
        payload: { msg: error || 'Unable to validate data' },
      });
      throw error;
    }
  };

  const createWeightDryRun = async () => {
    setIsLoading(true);

    const updatedTemplateData = { ...mainTemplateData };
    if (updatedTemplateData?.subGroup?.categories) {
      updatedTemplateData.subGroup.categories = updatedTemplateData.subGroup.categories.map(category => {
        const cap = capWeights[category.text];
        return {
          ...category,
          minCap: cap ? cap.minCap : null,
          maxCap: cap ? cap.maxCap : null,
        };
      });
    }

    const payload = {
      ...updatedTemplateData,
      id: weightName,
      description: weightDescription,
      settings: { ...updatedTemplateData?.settings, rounding: roundingValues, maxIterations: maxIterations ?? updatedTemplateData?.settings?.maxIterations },
    };
    try {
      const response = await fetchPostJson(
        `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced/dry-run`,
        token,
        payload,
      );
      setWeightResult(response);

      if (response.overallStats) {
        dispatch({
          type: 'SHOW_SUCCESS_NOTIFICATION',
          payload: { msg: 'Dry run successful' },
        });
        setCurrentStep(6);
      } else if (response.length > 0 || response.error) {
        throw response.error || response[0];
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch({
        type: 'SHOW_ERROR_NOTIFICATION',
        payload: { msg: error || 'Error occured' },
      });
    }
  };

  const createWeight = async () => {
    setIsLoading(true);

    const updatedTemplateData = { ...mainTemplateData };
    if (updatedTemplateData?.subGroup?.categories) {
      updatedTemplateData.subGroup.categories = updatedTemplateData.subGroup.categories.map(category => {
        const cap = capWeights[category.text];
        return {
          ...category,
          minCap: cap ? cap.minCap : null,
          maxCap: cap ? cap.maxCap : null,
        };
      });
    }

    const payload = {
      ...updatedTemplateData,
      id: universeDescription,
      description: universeDescription,
      settings: { ...updatedTemplateData?.settings, rounding: roundingValues, maxIterations: maxIterations ?? updatedTemplateData?.settings?.maxIterations },
    };
    try {
      await fetchPostJson(
        `an/projects/${projectId}/analysis/${datasetId}/weighting/advanced`,
        token,
        payload,
      );
      setIsLoading(false);
      dispatch({
        type: 'SHOW_SUCCESS_NOTIFICATION',
        payload: { msg: 'Advanced Weight created successfully' },
      });
      onClose();
    } catch (error) {
      setIsLoading(false);
      dispatch({
        type: 'SHOW_ERROR_NOTIFICATION',
        payload: { msg: error || 'Unable to create weights' },
      });
    }
  };

  const renderContent = (): JSX.Element | null => {
    switch (currentStep) {
      case 1:
        return (
          <StepOne
            createUniverse={createUniverse}
            universeDefinition={universeDefinition}
            universeDescription={universeDescription}
            setUniverseDefinition={setUniverseDefinition}
            setUniverseDescription={setUniverseDescription}
            setCreateUniverse={setCreateUniverse}
            weightName={weightName}
            setWeightName={setWeightName}
            weightDescription={weightDescription}
            setWeightDescription={setWeightDescription}
          />
        );
      case 2:
        return (
          <StepTwo
            token={token}
            projectId={projectId}
            datasetId={datasetId}
            mainTemplateData={mainTemplateData}
            setMainTemplateData={setMainTemplateData}
            validateTemplateData={validateTemplateData}
            isValidating={isLoading}
            setShouldValidate={setShouldValidate}
          />
        );
      case 3:
        return (
          <StepThree
            templateData={mainTemplateData}
            capWeights={capWeights}
            setCapWeights={setCapWeights}
            maxIterations={maxIterations || mainTemplateData?.settings?.maxIterations}
            setMaxIterations={setMaxIterations}
          />
        );
      case 4:
        return <StepFour setRoundingValues={setRoundingValues} roundingValues={roundingValues} />;
      case 5:
        return (
          <StepFive
            templateData={mainTemplateData}
            roundingValues={roundingValues}
            capping={capWeights}
            weightName={weightName}
            weightDescription={weightDescription}
            isLoading={isLoading}
            maxIterations={maxIterations || mainTemplateData?.settings?.maxIterations}
          />
        );
      case 6:
        return <StepSix weightResult={weightResult} isLoading={isLoading} />;
      default:
        return null;
    }
  };

  return (
    <div className="container-fluid d-flex flex-column p-2 h-100 position-relative">
      {currentStep !== 1 && (
        <h3 className="p-2 font-weight-bold font-italic font-size-base">
          {universeDefinition ? `Universe: ${universeDescription}` : ''}
        </h3>
      )}
      <div className="flex-grow-1 p-4 overflow-auto mb-4">{renderContent()}</div>

      <div className="position-absolute bottom-right-end">
        <ActionsButtons
          currentStep={currentStep}
          totalSteps={TOTAL_STEPS}
          isNextDisabled={isNextDisabled}
          isPreviousDisabled={currentStep === 1 || isLoading}
          onNext={handleNext}
          onBack={handleBack}
        />
      </div>
    </div>
  );
};

export default AdvancedWeightingWizard;
