import { Input, Switch } from '@progress/kendo-react-inputs';
import { Button } from '@progress/kendo-react-buttons';


interface StepOneProps {
  universeDescription: string;
  setUniverseDescription: (value: string) => void;
  universeDefinition: string;
  setUniverseDefinition: (value: string) => void;
  createUniverse: boolean;
  setCreateUniverse: (value: boolean) => void;
  weightName: string;
  setWeightName: (value: string) => void;
  weightDescription: string;
  setWeightDescription: (value: string) => void;
}

const StepOne = ({ createUniverse, setCreateUniverse, universeDescription, universeDefinition, weightName, weightDescription, setWeightDescription, setUniverseDefinition, setUniverseDescription, setWeightName }: StepOneProps) => {
  return (
    <div className="k-form">
      <h5>Weighting Scheme Name</h5>
      <div className="k-form-field d-flex align-items-center">
        <div className='p-4 mr-4 w-25'>
          <label htmlFor="weight-name" className="k-label">Name*</label>
          <p className="font-italic small text-gray">Must be one Alphanumeric word</p>
        </div>
        <div>
          <Input
            className='w-auto'
            id='weight-name'
            value={weightName}
            onChange={(e) => setWeightName(e.target.value as string)}
            required
          />
        </div>
      </div>

      <div className="k-form-field d-flex align-items-center">
        <div className='p-4 mr-4 w-25'>
          <label htmlFor="weight-description" className="k-label">Description</label>
          <p className="font-italic small text-gray">Optional, appears on tables when this scheme is applied</p>
        </div>
        <div>
          <Input
            className='w-auto'
            id='weight-description'
            value={weightDescription}
            onChange={(e) => setWeightDescription(e.target.value as string)}
          />
        </div>
      </div>

      <h5>Universe</h5>
      <div className="k-form-field d-flex align-items-center">
        <div className='p-4 mr-4 w-25 align-items-center'>
          <label htmlFor="create-universe" className="k-label">Create Universe</label>
          <p className='font-italic small text-gray'>Filter your sub group(s) universe</p>

        </div>
        <div>
          <Switch
            id="create-universe"
            checked={createUniverse}
            onChange={(e) => setCreateUniverse(e.target.value)}
            size="small"
          />
        </div>
      </div>

      <div className="k-form-field d-flex align-items-center">
        <div className='p-4 mr-4 w-25'>
          <label htmlFor="universe-description" className="k-label">Universe Description</label>
          <p className="font-italic small text-gray">Universe description</p>
        </div>
        <div>
          <Input
            className='w-auto'
            id='universe-description'
            value={universeDescription}
            onChange={(e) => setUniverseDescription(e.target.value as string)}
          />
        </div>
      </div>

      <div className="k-form-field d-flex align-items-center">
        <div className='p-4 mr-4 w-25'>
          <label htmlFor="universe-definition" className="k-label">Universe definition</label>
          <p className="font-italic small text-gray">Universe definition</p>
        </div>
        <div className="d-flex flex-nowrap">
          <input className="form-control left-corners-round h-32"
            placeholder="Filter"
            value={universeDefinition}
            onChange={(e) => setUniverseDefinition(e.target.value as string)} />
          <Button
            className="btn btn-primary right-corners-round"
            iconClass="fas fa-ellipsis-h"
            onClick={() => { }}
          />
        </div>

      </div>
    </div>
  );

}


export default StepOne;