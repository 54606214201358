import { HorizontalBarChart } from './HorizontalBarChart/HorizontalBarChart';
import VerticalBarChart from './VerticalBarChart/VerticalBarChart';
import LineChart from './LineChart/LineChart';
import PieChart from './PieChart/PieChart';
import DonutChart from './DonutChart/DonutChart';
import RadarChart from './RadarChart/RadarChart';
import ProfileChart from './ProfileChart/ProfileChart';
import ProfileMidChart from './ProfileMidChart/ProfileMidChart';


export default function ChartTypeContent({ data, currentZoom, additionalSettings }: { data: TODO, currentZoom?: number, additionalSettings?: {} }) {
  const chartType = data[0]?.type

  switch (chartType) {
    case 'hbar':
      return (
        <HorizontalBarChart
          data={data}
          zoomLevel={currentZoom}
          additionalSettings={additionalSettings}
        />
      )
    case 'vbar':
      return (
        <VerticalBarChart
          data={data}
          zoomLevel={currentZoom}
        />
      )
    case 'line':
      return (
        <LineChart
          data={data}
          zoomLevel={currentZoom}
        />
      )
    case 'pie':
      return (
        <PieChart
          data={data}
          zoomLevel={currentZoom}
        />
      )
    case 'doughnut':
      return (
        <DonutChart
          data={data}
          zoomLevel={currentZoom}
        />
      )
    case 'radar':
      return (
        <RadarChart
          data={data}
          zoomLevel={currentZoom}
        />
      )
    case 'profile':
      return (
        <ProfileChart
          data={data}
          zoomLevel={currentZoom}
        />
      )
    case 'profilemid':
      return (
        <ProfileMidChart
          data={data}
          zoomLevel={currentZoom}
        />
      )
    default:
      return (
        <div />
      )
  }
}