import { useState } from "react";
import { Editor } from "@monaco-editor/react";
import { useDispatch, useSelector } from "react-redux";

import { Icon } from "../../../shared/Icon/Icon";
import type { RootState } from "../../../../store/reducers/rootReducer";
import type { CustomDataOpsData } from "../../../../interfaces/customDataOpsInterface/customDataOpsInterface";
import { updateCustomDataOpsData, updateCustomDataOpsEditorTheme } from "../../../../store/reducers/customDataOpsStateReducer/customDataOpsStateReducer";
import { fetchPatchJson } from "../../../../services/services";

type Props = {
  selectedItem: CustomDataOpsData;
  editorValues: { html: string; properties: string; };
  setEditorValues: (values: { html: string; properties: string; }) => void;
}

const editorLanguageTabs = ["HTML", "Properties"] as const;

export const CustomDataOpsEditTabContent = ({ selectedItem, editorValues, setEditorValues }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { editorTheme, customDataOpsData } = useSelector((state: RootState) => state.customDataOpsStateReducer);
  const [activeEditorLanguageTab, setActiveEditorLanguageTab] = useState<(typeof editorLanguageTabs[number])>('HTML');
  const [isLoading, setIsLoading] = useState(false);

  const onSaveHandler = () => {
    setIsLoading(true);
    const body = { ...selectedItem, html: editorValues.html, props: JSON.parse(editorValues.properties) };
    fetchPatchJson(`su/customtools/${selectedItem.id}`, token, body)
      .then((res: TODO) => res.json())
      .then((data: TODO) => {
        setIsLoading(false);
        if (data.error || data.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: data.error || data.message } });
        } else {
          if (customDataOpsData) {
            setEditorValues({
              html: data.html,
              properties: data.props ? JSON.stringify(data.props, null, 2) : "[]"
            })
            dispatch(updateCustomDataOpsData(customDataOpsData.map(item => item.id === selectedItem.id ? { ...item, ...data } : item)));
          }
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Item updated successfully' } });
        }
      })
  }

  return (
    <div className="d-flex flex-column gap-lg w-100">
      <div className="d-flex justify-content-between">
        <div className="list-group list-group-horizontal">
          {
            editorLanguageTabs.map(tab =>
              <button
                key={tab}
                type="button"
                onClick={() => setActiveEditorLanguageTab(tab)}
                className={`list-group-item list-group-item-action py-1 ${activeEditorLanguageTab === tab ? 'active' : ''}`}>
                {tab}
              </button>
            )
          }
        </div>
        <div className="d-flex gap-md">
          <button
            type='button'
            title="Editor theme"
            className="btn btn-shadow px-1"
            onClick={() => dispatch(updateCustomDataOpsEditorTheme(editorTheme === 'vs-dark' ? 'vs-light' : 'vs-dark'))}>
            <Icon type="editor-theme" className="pe-none" />
          </button>
          <button
            type='button'
            className="btn btn-shadow"
            disabled={isLoading}
            onClick={onSaveHandler}>
            {isLoading && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />}
            <span>Save changes</span>
          </button>
        </div>
      </div>
      {
        activeEditorLanguageTab === 'HTML' &&
        <Editor
          theme={editorTheme}
          defaultLanguage={'html'}
          value={editorValues.html}
          options={{ tabSize: 8, fontSize: 13 }}
          className={editorTheme === 'vs-light' ? 'border' : ''}
          onChange={(val) => setEditorValues({ ...editorValues, html: val || "" })}
        />
      }
      {
        activeEditorLanguageTab === 'Properties' &&
        <Editor
          theme={editorTheme}
          defaultLanguage={'json'}
          value={editorValues.properties}
          options={{ tabSize: 8, fontSize: 13 }}
          className={editorTheme === 'vs-light' ? 'border' : ''}
          onChange={(val) => setEditorValues({ ...editorValues, properties: val || "" })}
        />
      }
    </div>
  )
}