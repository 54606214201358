
import { Button } from '@progress/kendo-react-buttons';

interface ActionsButtonsProps {
  currentStep: number;
  totalSteps: number;
  onNext: () => void;
  onBack: () => void;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
}

const ActionsButtons: React.FC<ActionsButtonsProps> = ({
  currentStep,
  totalSteps,
  onNext,
  onBack,
  isNextDisabled,
  isPreviousDisabled
}) => {
  return (
    <div className="d-flex justify-content-end pb-4 mt-auto bg-white">
      <div className="d-flex p-4">
        <Button
          onClick={onBack}
          className="btn btn-outline-dark mr-2 d-flex"
          disabled={isPreviousDisabled}
        >
          <i className="fas fa-chevron-left" /> Back
        </Button>

        {currentStep < totalSteps ? (
          <Button
            onClick={onNext}
            className="btn btn-dark d-flex"
            disabled={isNextDisabled}
          >
            {currentStep === 5 ? "Run" : "Next"} <i className="fas fa-chevron-right" />
          </Button>
        ) : (
          <Button
            onClick={onNext}
            className="btn btn-dark d-flex"
          >
            Finish <i className="fas fa-check" />
          </Button>
        )}
      </div>
    </div>

  );
};

export default ActionsButtons;
