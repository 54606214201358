import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import type { RootState } from "../../../store/reducers/rootReducer";
import { updateCustomDataOpsData, updateCustomDataOpsShowDeleteModal } from "../../../store/reducers/customDataOpsStateReducer/customDataOpsStateReducer";
import { fetchDeleteJson } from "../../../services/services";

type Props = {
  item: string;
}

export const CustomDataOpsDeleteModal = ({ item }: Props) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customDataOpsData } = useSelector((state: RootState) => state.customDataOpsStateReducer);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => dispatch(updateCustomDataOpsShowDeleteModal({ show: false, item: "" }));

  const deleteItem = () => {
    setIsLoading(true);
    fetchDeleteJson(`su/customtools/${item}`, token)
      .then((data: TODO) => {
        setIsLoading(false);
        if (data.error || data.message) {
          setErrorMessage(data.error || data.message);
        } else {
          const updatedData = customDataOpsData?.filter((data) => data.id !== item);
          if (updatedData) {
            dispatch(updateCustomDataOpsData(updatedData));
          }
          handleClose();
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Item deleted successfully' } });
        }
      })
  }

  return (
    <Dialog title={"Delete item"} onClose={handleClose} width={500}>
      <div className="p-4">
        <p className="m-0">Are you sure you would like to delete this item?</p>
        {
          errorMessage &&
          <div className="alert alert-danger mt-3 mb-0" role="alert">
            {errorMessage}
          </div>
        }
      </div>
      <DialogActionsBar>
        <button
          type="button"
          className="k-button btn-secondary"
          onClick={handleClose}>
          Cancel
        </button>
        <button
          type="button"
          className="k-button btn-danger"
          disabled={isLoading}
          onClick={deleteItem}>
          Delete
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}