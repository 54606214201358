import { Fragment, memo, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../../../../../../../store/reducers/rootReducer";
import { SurveyFilterBuilder } from "../../../../../../../../Survey/SurveyTabContent/SurveyDesignTabContent/SurveyFilterBuilder/SurveyFilterBuilder";
import { fetchPutJson as updateTool, fetchPostResOrJson as postWorkflow } from "../../../../../../../../../services/services";

type Props = {
  iframeUrl: string
  dataForIframe: { inputStructure: TODO, filter: string, props: TODO, state: TODO }
}

const noCache = new Date().getTime();
const iFrameOrigin = "https://saweupfpublicstaging.blob.core.windows.net" as const;

export const CustomToolTabContentIframe = memo(({ iframeUrl, dataForIframe }: Props) => {
  const dispatch = useDispatch();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const params: { name: string, workflow: string } = useParams();

  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);
  const [dataFromIframe, setDataFromIframe] = useState({ xml: "", state: {} });
  const [showFilterBuilder, setShowFilterBuilder] = useState({ display: false, filterData: {}, primaryIndex: null, manualFilterExpression: null });

  const dataForIframeCopy = JSON.parse(JSON.stringify(dataForIframe));

  useEffect(() => {
    if (workflowData.runCustomWorkflow || workflowData.saveCustomWorkflow) {
      getXmlResultFromIframe();
    }
  }, [workflowData.runCustomWorkflow, workflowData.saveCustomWorkflow]);

  const getXmlResultFromIframe = () => {
    iframeRef?.current?.contentWindow?.postMessage("generateXmlAndProps", iFrameOrigin);
  };

  const sendDataToIframeOnLoad = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.contentWindow?.postMessage({ type: "dataForIframe", data: dataForIframeCopy }, iFrameOrigin);
    }
  };

  const sendFilterExpressionToIframe = (expression: string) => {
    const iframe = iframeRef.current;
    if (iframe) {
      setShowFilterBuilder({ display: false, filterData: [], primaryIndex: null, manualFilterExpression: null });
      iframe.contentWindow?.postMessage({ type: "filterExpression", data: expression }, iFrameOrigin);
    }
  }

  const getDataFromIframe = useCallback((event: { origin: string, data: { type: string, result: typeof dataFromIframe } }) => {
    if (event.origin === iFrameOrigin) {
      if (event.data.type === "returnXmlAndProps") {
        setDataFromIframe(event.data.result);
        if (workflowData.saveCustomWorkflow) {
          dispatch({ type: "SET_SAVE_CUSTOM_WORKFLOW", payload: false });
          // LOGIC FOR SAVING THE WORKFLOW
          const body = JSON.parse(JSON.stringify(workflowData.selectedTool));
          body.state = JSON.stringify(event.data.result.state);
          body.definition = event.data.result.xml;
          updateTool(`projects/${params.name}/workflows/${params.workflow}/tools/${workflowData.selectedTool.id}`, token, body)
            .then((res: TODO) => {
              if (res && !res.message && !res.error) {
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The tool has been saved successfully' } })
                dispatch({ type: 'SET_TOOL_SELECTED', payload: res })
              } else {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
              }
            })
        } else if (workflowData.runCustomWorkflow) {
          dispatch({ type: "SET_RUN_CUSTOM_WORKFLOW", payload: false });
          // LOGIC FOR RUNNING THE WORKFLOW
          postWorkflow(`projects/${params.name}/workflows/${params.workflow}`, token)
            .then((res: TODO) => {
              if (res.status === 202) {
                dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Processing workflow...' } })
              } else if (res.message) {
                dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${res.error ? res.error : res.message}` } })
              }
            })
        }
      }
    }
  }, [workflowData.saveCustomWorkflow, workflowData.runCustomWorkflow, token, dispatch, params.name, params.workflow, workflowData.selectedTool]);

  const openFilterBuilderFromIframe = useCallback((event: { origin: string, data: string }) => {
    if (event.origin === iFrameOrigin) {
      if (event.data === "openFilterBuilder") {
        if (dataForIframeCopy.inputStructure.questions) {
          setShowFilterBuilder({
            display: true,
            filterData: dataForIframeCopy.inputStructure,
            primaryIndex: null,
            manualFilterExpression: null
          });
        } else {
          alert('No input dataset/survey found');
        }
      }
    }
  }, [dataForIframeCopy.inputStructure]);

  useEffect(() => {
    window.addEventListener("message", getDataFromIframe);
    window.addEventListener("message", openFilterBuilderFromIframe);

    return () => {
      window.removeEventListener("message", getDataFromIframe);
      window.removeEventListener("message", openFilterBuilderFromIframe);
    };
  }, [getDataFromIframe, openFilterBuilderFromIframe]);

  return (
    <Fragment>
      {
        showFilterBuilder.display &&
        <SurveyFilterBuilder
          token={token}
          type={'infoText'}
          option={'filter'}
          surveyId={'surveyId'}
          combineFilterData={[]}
          selectedTab={undefined}
          onSaveTextFilter={undefined}
          filterData={showFilterBuilder.filterData}
          manualFilterExpression={showFilterBuilder.manualFilterExpression}
          onSaveFilter={(expression: string) => sendFilterExpressionToIframe(expression)}
          handleClose={() => setShowFilterBuilder({ display: false, filterData: [], primaryIndex: null, manualFilterExpression: null })}
        />
      }
      <iframe
        ref={iframeRef}
        title="Custom data ops item"
        className="border rounded h-100"
        src={`${iframeUrl}?nocache=${noCache}`}
        onLoad={sendDataToIframeOnLoad}
      />
    </Fragment>
  )
});